function chargementController($http, DatatableLang, $state, $scope, WS, $window, $translate, $uibModal, $locale, AppSettings, $sce, $compile) {
    'ngInject';
    $locale.NUMBER_FORMATS.GROUP_SEP = "";
    $locale.NUMBER_FORMATS.DECIMAL_SEP = ".";

    const vm = this;
    vm.decimal = AppSettings.decimal || 3;


    vm.group_date = false;
    vm.chargement = {};
    // vm.chargement.ch = {};
    vm.total_casier_charg = 0;
    vm.total_montant_charg = 0;
    vm.produits = {};
    vm.chargementu = {};
    vm.camion = {};
    vm.camion.details_categ = [];
    vm.totalsortiecamion = {};

    vm.csvfile = {};
    vm.auth_chargement = "1";
    vm.chargement_apply_filter = { chargement: vm.auth_chargement };
    vm.caisse = {};

    vm.caisse_date = moment().format('YYYY-MM-DD');
    vm.current_date = moment().format('YYYY-MM-DD');
    vm.current_date_format_fr = moment().format('DD/MM/YYYY');



    vm.search = {
        date_debut: vm.current_date,
        date_fin: vm.current_date,
        user_code: '',
        gamme_code: '',
        famille_code: '',
        sousfamille_code: '',
        marque_code: '',
        produit_code: '',
    };

    vm.caisse_espece = 0;
    vm.caisse_cheque = 0;
    vm.caisse_credit = 0;
    vm.caisse_depense = 0;
    vm.caisse_carburant = 0;
    vm.caisse_entretien = 0;
    vm.caisse_gratuite = 0;
    vm.caisse_recouvrement = 0;
    vm.caisse_versement_banque = 0;
    vm.caisse_remise = 0;
    vm.caisse_tarifs = 0;
    vm.creditgms = 0;
    vm.credit_collectivite = 0;
    vm.credit_collectivite_depot = 0;
    vm.caisse_dlc = 0;
    vm.caisse_avoir = 0;
    vm.caisse_ticket = 0;
    vm.this_caisse = {};
    vm.applyfilter = function () {
        if (vm.auth_chargement == "0") {
            vm.chargement_apply_filter = '';
        } else {
            vm.chargement_apply_filter = { chargement: vm.auth_chargement };
        }
    };

    vm.setColMdBilan = () => {
        if (vm.hidePanelLeft) {
            vm.col_md_bilan = '12';
        } else {
            vm.col_md_bilan = '9';
        }
    }

    WS.getScache('societes')
        .then(function (response) {
            vm.societes = response.data[0];
            var contrat_code = vm.societes.contrat;
            /*console.log(response);
            console.log(contrat_code);*/
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                cache: false
            }).then(function successCallback(response) {
                vm.global_gestion_stock = response.data.gestion_stock;
            }, function errorCallback(response) { });
            $scope.$apply();

        })
        .then(null, function (error) {
            console.log(error);
        });
    vm.getChargements = function (page = 1, per_page = 30) {
        var data = {
            page,
            per_page,
            date_debut: vm.date_debut,
            date_fin: vm.date_fin,
            user_code: vm.user_code,
            type: vm.type,
            numero: vm.numero_mvt
        };
        WS.getData('mouvements/getChargements', data).then(
            function (response) {
                vm.chargements = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = Number(response.data.per_page);
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }

                $scope.$apply();
                // $('.table_chargement').DataTable({
                //     "language": DatatableLang,
                //     order: [
                //         [3, 'DESC']
                //     ],
                //     "pageLength": 50
                // });
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };


    vm.getRetours = function (page = 1, per_page = 30) {
        var data = {
            page,
            per_page,
            date_debut: vm.date_debut,
            date_fin: vm.date_fin,
            user_code: vm.user_code,
        };
        WS.getData('mouvements/getRetours', data).then(
            function (response) {
                vm.retours = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = Number(response.data.per_page);
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }

                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }


    vm.reinitChargement = function (numero) {
        swal({
            title: $translate.instant('Chargement'),
            text: $translate.instant("voulez vous vraiment ré-envoyer ce chargement!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {

            $uibModal.open({
                animation: false,
                templateUrl: 'myModalContent.html',
                size: 'md',
                backdrop: true,
                controller: function ($scope, $translate) {
                    $scope.message_password = $translate.instant('confirmer le mot de passe');
                    $scope.current_numero = vm.current_numero;

                    $scope.submitReinitChargement = function () {
                        if ($scope.set_password != '' && $scope.set_password != undefined) {
                            var data = {
                                numero: vm.current_numero,
                                password: $scope.set_password
                            };
                            console.log(data);
                            WS.post('chargements/reinitChargement', data).then(
                                function (response) {
                                    if (response.data.success) {
                                        swal(
                                            $translate.instant('Succès'),
                                            $translate.instant('Chargement envoyé avec succé'),
                                            'success'
                                        ).then(function () {
                                            $window.location.reload();
                                        }).catch(function () {
                                            $window.location.reload();
                                        });;
                                    } else {
                                        swal(
                                            $translate.instant('Attention'),
                                            $translate.instant(response.data.message),
                                            'warning'
                                        );
                                    }

                                },
                                function () {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant('erreur de chargement des données!!'),
                                        'warning'
                                    );
                                }
                            );
                        } else {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('Mot de passe Obligatoire'),
                                'warning'
                            );
                        }
                    }
                }
            });
        });

    }

    vm.exportDemande = function (numero) {
        var url = 'mouvements/dc/' + numero;
        WS.get(url).then(function (response) {
            var filename = "Demande chargement-" + numero;

            var file = {
                sheetid: "Demande chargement-" + numero,
                headers: true,
                caption: {
                    title: "Demande chargement-" + numero,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'numero', title: 'Numero' },
                    { columnid: 'commercial_code', title: 'Code Commercial' },
                    { columnid: 'depot_code', title: 'Depot' },
                    { columnid: 'produit_code', title: 'Code Produit' },
                    { columnid: 'code_a_barre', title: 'Code a barre' },
                    { columnid: 'libelle', title: 'Article' },
                    { columnid: 'quantite', title: 'Quantite' },
                    { columnid: 'created_at', title: 'Date' }

                ],
                row: {
                    style: function (sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function (error) {
            console.log(error);
        });
    };


    vm.getFirstSociete = function () {
        WS.get('societes/getFirstSociete').then(
            function (response) {
                vm.societe_global = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };
    vm.getFirstSousSociete = function () {
        WS.get('soussocietes/first')
            .then(function (response) {
                vm.societe = response.data;

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCommercialByEquipement = function (code, liste) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['code'] == code) {
                return liste[i];
            }
        }
    }



    vm.getDemandes = function () {
        WS.getScache('mouvements/dc').then(
            function (response) {
                vm.demandes = response.data;
                $scope.$apply();
                $('.table_chargement').DataTable({
                    "language": DatatableLang,
                    order: [
                        [3, 'DESC']
                    ]
                });
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    }

    vm.detailsdemandes = function () {
        var numero = $state.params.numero;
        vm.detailsdemandes = [];
        WS.getScache('mouvements/dc/' + numero).then(
            function (response) {
                vm.detailsdemandes = response.data;
                vm.getMoyByCommercialByProduct(response.data[0].commercial_code);
                vm.total_valeur_camion = 0;
                vm.total_valeur_demande = 0;
                _.each(vm.detailsdemandes, element => {
                    vm.total_valeur_camion += (element.stocks * element.prix_ttc);
                    vm.total_valeur_demande += (element.quantite * element.prix_ttc);

                });

                $scope.$apply();
                $('.table_chargement').DataTable({
                    "language": DatatableLang
                });
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    }

    vm.detailsdemandesBySSociete = function () {
        var numero = $state.params.numero;
        vm.detailsdemandes = [];
        WS.getData('mouvements/getDetailsDCBySsousociete', {
            numero, soussociete_code: vm.soussociete_code
        }).then(
            function (response) {
                vm.detailsdemandes = response.data;
                vm.total_valeur_camion = 0
                vm.total_valeur_demande = 0

                vm.getMoyByCommercialByProduct(response.data[0].commercial_code);
                _.each(vm.detailsdemandes, element => {
                    vm.total_valeur_camion += (element.stocks * element.prix_ttc);
                    vm.total_valeur_demande += (element.quantite * element.prix_ttc);

                });

                $scope.$apply();
                $('.table_chargement').DataTable({
                    "language": DatatableLang
                });
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    }

    vm.getMoyByCommercialByProduct = function (commercial) {
        var url = 'produits/moyenneCommercial' + '/' + commercial + '/bl';

        WS.get(url, vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.moyenneCommercial = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.updateChargementFromList = () => {
        var data = vm.current_chargement;
        WS.post('chargements/updateChargementFromList', data).then(
            function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('chargement modifiée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    }).catch(function () {
                        $window.location.reload();
                    });

                } else {
                    swal(
                        $translate.instant('Oups!'),
                        $translate.instant("Vous n'êtes pas autorisé"),
                        'warning'
                    );
                }

                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );

    }
    vm.type = $state.params.type ? $state.params.type : 'chargement';
    vm.updateChargement = function () {
        vm.numero = $state.params.numero;
        vm.type = $state.params.type ? $state.params.type : 'chargement';
        vm.chprod = {};
        WS.get('mouvements/getChargementsByNumero/' + vm.numero + '/' + vm.type).then(
            function (response) {
                $scope.$apply();
                vm.chargementu.produits = {};
                vm.chargementu.ch.numero = response.data.numero;
                vm.chargementu.ch.commercial = response.data.user.code;
                vm.chprod = response.data.produits;
                vm.details = response.data.user;

                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    }



    vm.exportchargement = function (numero) {
        var url = 'mouvements/ByCode/' + numero;
        WS.get(url).then(function (response) {
            var filename = "chargement-" + numero;

            var file = {
                sheetid: "chargement-" + numero,
                headers: true,
                caption: {
                    title: "chargement-" + numero,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'numero', title: 'Numero' },
                    { columnid: 'commercial_code', title: 'Code Commercial' },
                    { columnid: 'depot_code', title: 'Depot' },
                    { columnid: 'produit_code', title: 'Code Produit' },
                    { columnid: 'code_a_barre', title: 'Code a barre' },
                    { columnid: 'libelle', title: 'Article' },
                    { columnid: 'quantite', title: 'Quantite' },
                    { columnid: 'created_at', title: 'Date' }

                ],
                row: {
                    style: function (sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function (error) {
            console.log(error);
        });
    };


    vm.correctionPrixChargement = (type = "chargement") => {
        WS.post('chargements/correctionPrixChargement', { type })
            .then(function (response) {
                swal(
                    $translate.instant('Succès'),
                    $translate.instant('les prix de chargement d\'aujourd\'hui modifié avec Succès'),
                    'success'
                ).then(function () {
                    //$window.location.reload();
                }).catch(function () {
                    //$window.location.reload();
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.sendupdated = function (chargement) {

        WS.post('mouvements/UpdateChargementsByNumero', chargement)
            .then(function (response) {
                swal(
                    $translate.instant('Succès'),
                    $translate.instant('chargement modifiée avec Succès'),
                    'success'
                ).then(function () {
                    $window.location.reload();
                }).catch(function () {
                    $window.location.reload();
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.delete = function (id) {
        swal({
            title: $translate.instant('Produit'),
            text: $translate.instant("voulez vous vraiment supprimer ce Produit!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('mouvements/' + id).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Produit supprimée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('vous ne pouvez pas supprimer ce Produit!!'),
                        'warning'
                    );
                }
            );
        });
    }

    vm.converttochargement = function (demande) {
        console.log(demande);
        WS.put('mouvements/converttochargement/' + demande.numero + '/' + demande.date, demande)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Votre chargement est envoyé avec succées'),
                        'success'

                    ).then(function () {
                        $window.location.reload();
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.senddemande = function (demande) {
        if (vm.soussociete_code) {
            demande.soussociete_code = vm.soussociete_code;
            var demande_res = {};
            demande_res.ch = {};
            demande_res.soussociete_code = vm.soussociete_code;
            demande_res.date = demande.date;

            _.each(demande.ch, (element, index) => {
                if (!element.soussociete_code) {
                    demande_res.ch[index] = element;
                }
            })


            console.log(vm.soussociete_code, demande, demande_res)
            console.log(vm.soussociete_code, demande_res)
            WS.post('mouvements/UpdateDemandebyId', demande_res)
                .then(function (response) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('demande modifiée avec succées'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal(
                $translate.instant('Oups!'),
                $translate.instant('Veuillez bien choisir un distributeur'),
                'warning'
            )
        }

    }

    vm.store = function (chargement) {
        vm.show_loader = true;
        vm.btn_save = true;
        var ch = {};
        ch.produits = [];
        var charg = {};
        if (chargement.ch.type == 'retour') {
            charg.date_debut = vm.chargement.stat_date_debut;
            charg.date_fin = vm.chargement.stat_date_fin;
            charg.caisse = vm.chargement.stat_caisse;
            charg.caisse_code = vm.chargement.stat_caisse_code;
        } else {
            charg.date_debut = '';
            charg.date_fin = '';
            charg.caisse = '';
            charg.caisse_code = '';
        }

        var hors_stk = 0;

        if (vm.multi_camion == false) {
            console.log('chargement.ch ===> ', chargement.ch)
            _.each(chargement.ch, (value, key) => {
                if (
                    key != 'numero' && key != 'commercial' && key != 'colisage' && key != 'type' &&
                    key != 'envoi' && key != 'retour_hier' && key != 'retourI_hier' && key != 'demande_chargement' && key != 'date' && key != 'tournee_code' &&
                    key != 'code_jour' && key != 'n_lot' && typeof value == 'object'
                ) {

                    if (vm.chargement.ch.type == 'chargement') {
                        //test quota
                        if (vm.parametrages.use_quota == 1 && Number(value.quota) >= 0) {
                            var check_quota = vm.check_class_red_green(value.colisage, value.stk_quantite, value.produit_id, value.quota);

                            if (check_quota.red_clr == true) {
                                hors_stk = hors_stk + 1;
                            }

                        }
                        if (vm.societe_global.authorization_stock == 1) {
                            if (chargement.ch.retour_hier) {
                                if (chargement.ch.colisage == true) {
                                    if (
                                        (value.retour_hier_p) +
                                        (value.quantite * value.colisage) > value.stk_quantite || value.quantite < 0
                                    ) {
                                        hors_stk = hors_stk + 1;
                                    }
                                } else {
                                    if (value.retour_hier_p + value.quantite > value.stk_quantite || value.quantite < 0) {
                                        hors_stk = hors_stk + 1;
                                    }
                                }
                            } else {
                                if (chargement.ch.colisage == true) {
                                    if (value.quantite * value.colisage > value.stk_quantite || value.quantite < 0) {
                                        hors_stk = hors_stk + 1;
                                    }
                                } else {
                                    if (value.quantite > value.stk_quantite || value.quantite < 0) {
                                        hors_stk = hors_stk + 1;
                                    }
                                }
                            }


                        }
                    }

                    //var res_quantite = 0;
                    //console.log('value.quantite = (value.quantite == undefined) ? 0 : Number(value.quantite)' , value);
                    value.quantite = (value.quantite == undefined) ? 0 : Number(value.quantite);
                    if (vm.chargement.ch.type == 'chargement') {
                        value.retourI_hier_p = (value.retourI_hier_p == undefined) ? 0 : value.retourI_hier_p;
                        value.retour_hier_p = (value.retour_hier_p == undefined) ? 0 : value.retour_hier_p;
                        value.demande_chargement_p = (value.demande_chargement_p == undefined) ? 0 : value.demande_chargement_p;

                        value.quantite += value.retour_hier_p ? Number(value.retour_hier_p) : 0;
                        value.quantite += value.demande_chargement_p ? Number(value.demande_chargement_p) : 0;
                    }


                    if (vm.chargement.ch.colisage == true) {
                        //value.quantite = (Number(value.quantite) * Number(value.colisage));
                        value.quantite = (Number(value.quantite) + (Number(value.colisage) * Number(value.box_quantite)));
                    }

                    //chargement or inventaire
                    if (vm.chargement.ch.type == 'chargement' || vm.chargement.ch.type == 'inventaire' || vm.chargement.ch.type == 'retourI') {

                        if ((value.quantite != undefined && value.quantite != 0 && value.quantite != '' &&
                            value.quantite != "0" && value.quantite != null && value.quantite != NaN) ||
                            (value.retourI_hier_p != undefined && value.retourI_hier_p != 0 && value.retourI_hier_p != '' &&
                                value.retourI_hier_p != "0" && value.retourI_hier_p != null && value.retourI_hier_p != NaN)
                        ) {
                            ch.produits[key] = value;
                        }
                    }

                    // Retour
                    if (vm.chargement.ch.type == 'retour') {

                        if (
                            (value.quantite != undefined && value.quantite != 0 && value.quantite != '' &&
                                value.quantite != "0" && value.quantite != null && value.quantite != NaN)
                            ||
                            (value.quantite_dlc != undefined && value.quantite_dlc != 0 && value.quantite_dlc != '' &&
                                value.quantite_dlc != "0" && value.quantite_dlc != null && value.quantite_dlc != NaN)
                            ||
                            (value.quantite_casse != undefined && value.quantite_casse != 0 && value.quantite_casse != '' &&
                                value.quantite_casse != "0" && value.quantite_casse != null && value.quantite_casse != NaN)
                            ||
                            (value.gratuite != undefined && value.gratuite != 0 && value.gratuite != '' &&
                                value.gratuite != "0" && value.gratuite != null && value.gratuite != NaN)
                        ) {
                            ch.produits[key] = value;
                        }
                    }

                } else if (key == 'numero' || key == 'commercial' || key == 'colisage' ||
                    key == 'type' || key == 'envoi' || key == 'retour_hier' || key == 'retourI_hier' || key == 'demande_chargement' ||
                    key == 'date' || key == 'tournee_code' || key == 'code_jour') {
                    ch[key] = value;
                }
            });
            charg.ch = ch;
            charg.multi_camion = vm.multi_camion;
            // charg.ch.date = chargement.ch.date;
            // charg.ch.retour_hier = chargement.ch.retour_hier;
            // charg.ch.demande_chargement = chargement.ch.demande_chargement;
            charg.ch.produits = _.reject(ch.produits, _.isNull);
            charg.ch.produits = _.without(charg.ch.produits, undefined);
            charg.ch.soussociete_code = vm.soussociete_code;

            if (charg.ch.produits.length > 0) {
                if (charg.ch.type == "chargement" || charg.ch.type == "inventaire") {

                    if (hors_stk == 0) {
                        //vm.store,
                        WS.post('mouvements/chargementStore', charg)
                            .then(function (response) {
                                swal(
                                    $translate.instant('Succès'),
                                    $translate.instant('chargement ajoutée avec Succès'),
                                    'success'
                                ).then(function () {
                                    $window.location.reload();
                                    //$state.go("app.chargements.index", { numero: '' });
                                    vm.btn_save = false;
                                    vm.show_loader = false;
                                    $scope.$apply();
                                }).catch(function (error) {
                                    $window.location.reload();
                                    //$state.go("app.chargements.index", { numero: '' });
                                    vm.btn_save = false;
                                    vm.show_loader = false;
                                    $scope.$apply();
                                });
                                vm.btn_save = false;
                                vm.show_loader = false;
                                $scope.$apply();
                            })
                            .then(null, function (error) {
                                vm.btn_save = false;
                                vm.show_loader = false;
                                console.log(error);
                                $scope.$apply();
                            });
                    } else {
                        swal(
                            $translate.instant('Attention!'),
                            $translate.instant('veuillez bien vérifier les quantités supérieures à (stock, Quota) ou les quantite négative'),
                            'warning'
                        );
                        vm.btn_save = false;
                        vm.show_loader = false;
                        //$scope.$apply();
                    }
                } else {
                    console.log("+++++++++++++++");
                    console.log("ch", charg);
                    console.log("+++++++++++++++");
                    WS.post('mouvements/chargementStore', charg)
                        .then(function (response) {
                            swal(
                                $translate.instant('Succès'),
                                $translate.instant('chargement ajoutée avec Succès'),
                                'success'
                            ).then(function () {
                                vm.btn_save = false;
                                vm.show_loader = false;
                                $window.location.reload();
                                //$state.go("app.chargements.index", { numero: '' });

                            }).catch(function (error) {
                                vm.btn_save = false;
                                vm.show_loader = false;
                                $window.location.reload();
                                //$state.go("app.chargements.index", { numero: '' });

                            });
                            vm.btn_save = false;
                            vm.show_loader = false;
                            $scope.$apply();
                        })
                        .then(null, function (error) {
                            console.log(error);
                            vm.btn_save = false;
                            vm.show_loader = false;
                            $scope.$apply();

                        });
                }
            } else {
                swal(
                    $translate.instant('Attention!'),
                    $translate.instant('veuillez bien charger au moins un produit'),
                    'warning'
                );
                vm.btn_save = false;
                vm.show_loader = false;
            }

        } else {
            //console.log(vm.chargement);
            var panel = [];
            _.each(vm.chargement.inverse_multi_camion, (prod, predkey) => {
                //prod.total_chargement = 70;
                if (prod.total_chargement != undefined && prod.total_chargement != 0) {

                    if (vm.societe.authorization_stock == 1) {
                        if (chargement.ch.colisage == true) {
                            if (prod.total_chargement * prod.colisage > prod.stk_quantite) {
                                hors_stk = hors_stk + 1;
                            }
                        } else {
                            if (prod.total_chargement > prod.stk_quantite) {
                                hors_stk = hors_stk + 1;
                            }
                        }
                    }
                    if (hors_stk == 0) {
                        _.each(prod, (element, depot_key) => {
                            if (typeof element == 'object') {
                                if (element.quantite != 0) {
                                    if (panel[depot_key] == undefined) {
                                        panel[depot_key] = {};
                                        panel[depot_key]['produits'] = [];
                                        panel[depot_key]['depot_code'] = element.depot_code;
                                        panel[depot_key]['commercial_code'] = element.user_code;
                                    }
                                    if (chargement.ch.colisage == true) {
                                        var quantite = element.quantite * element.colisage;
                                    } else {
                                        var quantite = element.quantite;
                                    }
                                    panel[depot_key]['produits'].push({
                                        produit_code: element.produit_code,
                                        quantite: quantite,
                                        prix_ttc: element.prix_ttc,
                                        prix_achat_ttc: element.prix_achat_ttc
                                    });
                                }
                            }
                        });
                    }
                }
                //console.log("panel", panel);

            });

            if (hors_stk == 0) {
                panel = _.omit(panel, _.isEmpty); //remove all empty keys from panel array

                charg.chargements = panel;
                charg.multi_camion = vm.multi_camion;
                charg.type = vm.chargement.ch.type;
                charg.date = chargement.ch.date;
                charg.soussociete_code = vm.soussociete_code;
                console.log(charg);
                WS.post('mouvements/chargementStoreMultiCamion', charg)
                    .then(function (response) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('chargement ajoutée avec Succès'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                           // $state.go("app.chargements.index", { numero: '' });

                        }).catch(function (error) {
                            $window.location.reload();
                            //$state.go("app.chargements.index", { numero: '' });

                        });
                        vm.btn_save = true;
                        $scope.$apply();
                    })
                    .then(null, function (error) {
                        console.log(error);
                    });

            } else {
                swal(
                    $translate.instant('Attention!'),
                    $translate.instant('veuillez bien vérifier les quantités supérieures à stock'),
                    'warning'
                );
                vm.btn_save = false;
            }
        }

        // vm.show_loader = false;

    }

    vm.storeChargementOneDepot = function (depot_code, user_code) {
        var data = [];
        var quantite = 0;
        _.each(vm.produits, (prod, index) => {
            console.log(depot_code, user_code, prod.id, vm.chargement.multi_camion[depot_code][prod.id]);
            if (vm.chargement.multi_camion[depot_code][prod.id]) {
                if (
                    vm.chargement.multi_camion[depot_code][prod.id]['quantite'] != undefined &&
                    vm.chargement.multi_camion[depot_code][prod.id]['quantite'] != 0 &&
                    vm.chargement.multi_camion[depot_code][prod.id]['quantite'] != ''
                ) {
                    if (vm.chargement.ch.colisage) {
                        quantite = vm.chargement.multi_camion[depot_code][prod.id]['quantite'] * prod.colisage;
                    } else {
                        quantite = vm.chargement.multi_camion[depot_code][prod.id]['quantite'];
                    }
                    data.push({
                        user_code: user_code,
                        depot_code: depot_code,
                        produit_code: prod.code,
                        quantite: quantite,
                        prix_achat_ht: prod.prix_achat_ht,
                        prix_achat_ttc: prod.prix_achat_ttc,
                        prix_ht: prod.prix_ht,
                        prix_ttc: prod.prix_ttc,
                        tva: prod.tva,
                        date: vm.chargement['ch']['date'],
                        soussociete_code: vm.soussociete_code
                    });
                }
            }

        });
        if (data.length > 0) {
            WS.post('mouvements/storeChargementOneDepot', {
                data: data,
                retour_hier: vm.chargement['ch']['retour_hier'],
                demande_chargement: vm.chargement['ch']['demande_chargement'],
                user_code: user_code
            })
                .then(function (response) {
                    if (response.data.success == true && response.data.tab.length > 0) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('chargement ajouter avec succés'),
                            'success'
                        );
                        $(`#btn_save_${depot_code}`).removeClass("btn-success");
                        $(`#btn_save_${depot_code}`).addClass("btn-default");
                        $(`#btn_save_${depot_code}`).prop("disabled", true);

                        _.each(vm.produits, (prod, index) => {
                            $(`#quantite_${depot_code}_${prod.code}`).prop("disabled", true);
                        });
                    }
                    $scope.$apply();

                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal(
                $translate.instant('Attention!'),
                $translate.instant('veuillez bien vérifier les quantités'),
                'warning'
            );
        }

    };


    vm.clearform = function () {
        vm.loading = true;
        vm.findgamme = null;
        vm.findfamille = null;
        vm.findsousfamille = null;
        vm.getproduits();
    }
    vm.searchBy = function (gamme, famille, sousfamille) {
        vm.loading = true;

        var find = {};
        var i = 0;
        if (gamme != '' && gamme != null) {
            find['gamme'] = gamme;
            i++;
        }
        if (famille != '' && famille != null) {
            find['famille'] = famille;
            i++;
        }
        if (sousfamille != '' && sousfamille != null) {
            find['sousfamille'] = sousfamille;
            i++;
        }
        if (i == 0) { vm.loading = false; return; }

        WS.post('mouvements/searchByColumn', find)
            .then(function (response) {
                vm.produits = response.data.produits;
                vm.chargement.ch.numero = 'CH-' + response.data.countch;
                vm.loading = false;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    // $(document).keydown(function (e) {
    //     console.log('key code is: ' + e.which + ' ' + (e.ctrlKey ? 'Ctrl' : '') + ' ' +
    //         (e.shiftKey ? 'Shift' : '') + ' ' + (e.altKey ? 'Alt' : ''));
    //         if(e.which == 70 && e.ctrlKey){
    //             e.preventDefault();
    //             $('#searchbox').focus();
    //         }
    // });

    vm.allsocietes = {};


    vm.selectSoussocietesOnly = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                if (vm.soussocietes.length == 1) {
                    vm.soussociete_code = vm.soussocietes[0].code;
                }

                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };



    vm.checkIfNumeroExist = () => {
        if ($state.params.numero) {
            console.log('checkIfNumeroExist', $state.params.numero)
            vm.numero = $state.params.numero;
            WS.get('mouvements/getChargementsByNumero/' + vm.numero).then(
                function (response) {
                    vm.soussociete_code = response.data.user.soussociete_code;
                    var prods = response.data.produits;
                    vm.details_mvts = response.data.user;
                    vm.details_prods_mvt = _.indexBy(prods, 'code');

                    if (vm.numero && vm.soussociete_code) {
                        vm.getproduits()
                    }
                    $scope.$apply();
                },
                function (error) {
                    console.log('error : ->', error);
                }
            );
        }
    }

    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                _.each(vm.soussocietes, element => {
                    vm.allsocietes[element.code] = element.nom;
                })
                vm.checkIfNumeroExist();

                if (vm.soussocietes.length == 1) {
                    vm.soussociete_code = vm.soussocietes[0].code;
                    vm.getproduits();
                } else {

                }

                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };
    vm.getproduits = function () {

        WS.getData('mouvements/names', { soussociete_code: vm.soussociete_code })
            .then(function (response) {
                // vm.chargement = {};
                // vm.chargement.ch = {};
                vm.chargement.ch.numero = 'CH-' + response.data.countch.toString();
                vm.produits = response.data.produits;
                vm.loading = false;

                _.each(vm.produits, (prod, indprod) => {
                    vm.chargement['ch'][prod.id] = {};
                    vm.chargement['ch'][prod.id]['retour_hier_p'] = 0;
                    vm.chargement['ch'][prod.id]['quantite'] = 0;
                    vm.chargement['ch'][prod.id]['box_quantite'] = 0;
                    vm.chargement['ch'][prod.id]['produit_code'] = prod.code;
                    vm.chargement['ch'][prod.id]['colisage'] = prod.colisage;
                    vm.chargement['ch'][prod.id]['prix_ht'] = prod.prix_ht;
                    vm.chargement['ch'][prod.id]['prix_ttc'] = prod.prix_ttc;
                    vm.chargement['ch'][prod.id]['prix_achat_ht'] = prod.prix_achat_ht;
                    vm.chargement['ch'][prod.id]['prix_achat_ttc'] = prod.prix_achat_ttc;
                    vm.chargement['ch'][prod.id]['tva'] = prod.tva;
                    vm.chargement['ch'][prod.id]['hors_box'] = prod.hors_box;
                    vm.chargement['ch'][prod.id]['stk_quantite'] = prod.stk_quantite;
                    vm.chargement['ch'][prod.id]['produit_id'] = prod.id;
                    vm.chargement['ch'][prod.id]['produit_code'] = prod.code;
                    vm.chargement['ch'][prod.id]['caisse_code'] = prod.caisse_code;

                    if (vm.numero) {
                        console.log('vm.details_prods_mvt', vm.details_prods_mvt)
                        if (vm.details_prods_mvt[prod.code]) {
                            vm.chargement['ch'][prod.id]['quantite'] = vm.details_prods_mvt[prod.code].quantite;
                        }
                    }
                });
                if (vm.numero) {
                    console.log('vm.details_mvts', vm.details_mvts)
                    vm.chargement.ch.commercial = vm.details_mvts.commercial_code
                    vm.chargement.ch.type = vm.details_mvts.type
                }
                console.log(vm.chargement.commercial, vm.chargement.type)



                /* _.each(vm.selectdepots, (dep, inddep) => {
                     _.each(vm.produits, (prod, indprod) => {
                         $scope.$watch(vm.chargement.multi_camion[dep.code][prod.id], function(newvalue) {
                             console.log('watched' , newvalue);
                             vm.totalsortiecamion[prod.id] = vm.chargement.multi_camion[dep.code].reduce((s, f) => {
                                 return Number(s) + Number(f.quantite);              
                             }, 0);
                         }, true); 
                     });
                 });*/
                $scope.$apply();
                vm.total_Casier_chargement();



            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectSoussocietesForDc = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                _.each(vm.soussocietes, element => {
                    vm.allsocietes[element.code] = element.nom;
                })

                if (vm.soussocietes.length == 1) {
                    vm.soussociete_code = vm.soussocietes[0].code;
                    vm.detailsdemandesBySSociete();
                } else {
                    vm.detailsdemandes();

                }

                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };


    vm.changeDateFormat = () => {
        vm.current_date_format_fr = moment(vm.chargement.ch.date).format('DD/MM/YYYY');
    }






    vm.getproduitsChargementType = function () {
        WS.get('chargements/getproduitsChargementType')
            .then(function (response) {
                vm.produits = response.data;
                vm.loading = false;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getCommercials = function () {
        WS.getScache('users/cashvanprevendeur')
            .then(function (response) {
                vm.commercials = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getMarque = function () {
        WS.get('marques')
            .then(function (response) {
                vm.marques = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getAllproduits = function () {
        WS.get('produits/names')
            .then(function (response) {
                vm.produitsnames = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.importChargementStoreCSV = function (numero, commercial, type) {
        vm.csvfile.filetxt = vm.files[0];
        vm.csvfile.numero = numero;
        vm.csvfile.commercial = commercial;
        vm.csvfile.soussociete_code = vm.soussociete_code;
        vm.csvfile.type = type;
        vm.csvfile.verif_import = vm.verif_import;
        vm.csvfile.numero_import = vm.numero_import;
        vm.csvfile.check_gestion_stock = vm.global_gestion_stock;
        console.log(vm.csvfile);
        WS.postwithimg('mouvements/importChargementStoreCSV', vm.csvfile, "POST")
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant(response.data.message),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        $translate.instant('Oups!'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }
                if (response.data.error == true) {
                    swal(
                        $translate.instant('Oups!'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };

    $scope.moveCSV = function (element) {
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.csvfile.filetxt = event.target.result;
            $scope.$apply(function () {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };


    /**
     * init variables
     */

    vm.getUserInfo = function (user_code) {
        WS.getScache('users/getUserInfo/' + user_code).then(
            (response) => {
                vm.userinfo = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        )
    };

    vm.getCaisseByCode = function (caisse_code) {
        WS.getScache('caisses/' + caisse_code).then(
            (response) => {
                vm.this_caisse = response.data;
                //console.log('vm.this_caisse', vm.this_caisse);
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        )
    };
    vm.stat_caisse = 'caisse_non';

    vm.getValueByCodeByListe = function (code, liste) {
        if (!liste) return 0;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['produit_code'] == code) {
                return liste[i].total_vente_facture_par_article;
            }
        }
        return 0;
    }
    vm.total_vente_facture_par_article;


    /**
     * initialisation de  date_debut et date_fin
     */
    if ($state.params.date_debut && $state.params.date_fin) {
        vm.stat_date_debut = $state.params.date_debut;
        vm.stat_date_fin = $state.params.date_fin;
    } else {
        vm.stat_date_debut = vm.current_date;
        vm.stat_date_fin = vm.current_date;
    }

    if ($state.params.caisse_code) {
        vm.stat_caisse_code = $state.params.caisse_code;
    }
    //console.log('params', $state.params)
    if ($state.params.commercial_code) {
        vm.stat_commercial = $state.params.commercial_code;
    }
    if ($state.params.date_debut && $state.params.date_fin) {
        vm.stat_date_debut = $state.params.date_debut;
        vm.stat_date_fin = $state.params.date_fin;
    }
    if ($state.params.caisse_code && $state.params.commercial_code) {
        vm.stat_caisse = 'caisse_oui';
        vm.getUserInfo($state.params.commercial_code);
        vm.getCaisseByCode($state.params.caisse_code);
    }

    vm.stat_chargements = (reload_caisse = false) => {


        //console.log($state.params.caisse_code, vm.stat_caisse_code);
        if (vm.stat_caisse_code != '' && vm.stat_caisse_code != undefined) {
            vm.getCaisseByCode(vm.stat_caisse_code);
        }
        vm.show_loader = true;
        var data = {
            date_debut: vm.stat_date_debut,
            date_fin: vm.stat_date_fin,
            commercial: vm.stat_commercial,
            colisage: vm.stat_colisage,
            caisse: vm.stat_caisse,
            caisse_code: vm.stat_caisse_code,
        };
        WS.getData('chargements/stat_chargements', data)
            .then(function (response) {
                vm.show_generate_bl = false;
                vm.chargements_stats = response.data.chargements;
                vm.prices = response.data.prices;
                vm.last_retour = response.data.last_retour;

                vm.caisse_mois = response.data.caisse_mois;
                vm.caisse_total = response.data.caisse_total;

                vm.total_sum_retourhier_gajou = 0;
                _.each(vm.last_retour, element => {
                    if (element.hors_box != 1) {
                        vm.total_sum_retourhier_gajou += Number(element.quantite) / Number(element.colisage);
                    }
                })

                _.each(vm.chargements_stats, (element, index) => {
                    _.each(vm.prices, (price) => {
                        if (price.produit_code == element.code) {
                            //element.prix_ttc = price.prix_ttc;
                            //element.prix_ttc_unit = price.prix_ttc_unit;
                            vm.chargements_stats[index].prix_ttc = price.prix_ttc;
                            vm.chargements_stats[index].prix_ttc_unit = price.prix_ttc_unit;
                            vm.chargements_stats[index].prix_ht = price.prix_ht;
                            vm.chargements_stats[index].prix_ht_unit = price.prix_ht_unit;
                            //  console.log('updated : ', vm.chargements_stats[index].prix_ttc, price.prix_ttc);
                        }
                    });
                });
                vm.total_vente_facture_par_article = response.data.vente_factures;
                vm.total_vente_facture = 0;
                vm.caisse_commercial_imp = response.data.caisse_commercial;
                vm.total_reel = 0;
                vm.total_reel_ht = 0;
                vm.total_facture = 0;
                vm.total_sum_chargement = 0;
                vm.total_sum_retour = 0;
                vm.total_sum_dlc_casse = 0;
                vm.total_sum_dlc = 0;
                vm.total_sum_casse = 0;
                vm.total_sum_gratuite = 0;
                vm.total_sum_vente = 0;
                vm.total_sum_vente_hb = 0;
                vm.total_sum_vente_reel = 0;
                vm.total_sum_vente_net = 0;
                vm.total_ecart = 0;
                vm.total_sum_dlc_uniq_chiffre_mobile = 0;
                vm.total_sum_chargement_uniq = 0;
                vm.total_sum_retour_uniq = 0;
                vm.total_sum_casse_uniq = 0;
                vm.total_sum_dlc_uniq = 0;
                vm.total_sum_dlc_uniq_chiffre = 0;

                vm.total_sum_dlc_casse_uniq = 0;
                vm.total_dlc_casse_client = 0;

                vm.total_remises = 0;
                vm.total_tarifs = 0;
                vm.total_net_a_payer = 0;
                vm.total_espece = 0;
                vm.total_net_cheque = 0;
                vm.total_credit = 0;
                vm.total_rec_espece = 0;
                vm.total_rec_cheque = 0;

                vm.total_ca_gratuite_uniq = 0;
                vm.total_ca_casse_uniq = 0;
                vm.total_ca_dlc_uniq = 0;
                //caisse
                vm.caisse_espece = 0;
                vm.caisse_cheque = 0;
                vm.caisse_credit = 0;
                vm.caisse_depense = 0;
                vm.caisse_carburant = 0;
                vm.caisse_entretien = 0;
                vm.caisse_gratuite = 0;
                vm.caisse_recouvrement = 0;
                vm.caisse_versement_banque = 0;
                vm.caisse_remise = 0;
                vm.this_caisse.cloture = 0;
                vm.caisse_tarifs = 0;
                vm.total_sum_chargement_uniq_gajou = 0;
                vm.total_sum_chargement_uniq_gajou_hb = 0;
                vm.total_sum_retour_gajou = 0;
                vm.total_sum_vente_gajou = 0;
                vm.total_sum_vente_gajou_hb = 0;
                if (vm.chargements_stats.length > 0) {
                    //console.log('gratuite : ' )
                    _.each(vm.chargements_stats, (element, index) => {
                        if (element.hors_vente == 0) {

                            vm.total_sum_chargement_uniq += element.sum_chargement_uniq;
                            vm.total_sum_chargement_uniq_gajou += element.sum_chargement_uniq / element.colisage;
                            vm.total_sum_chargement_uniq_gajou_hb += element.sum_chargement_uniq_hb / element.colisage;

                            vm.total_sum_retour_uniq += element.sum_retour_uniq;
                            vm.total_sum_dlc_casse_uniq += element.sum_dlc_casse_uniq;
                            vm.total_sum_dlc_uniq += element.sum_dlc_uniq;
                            vm.total_sum_casse_uniq += element.sum_casse_uniq;
                            vm.total_sum_dlc_uniq_chiffre += element.ca_dlc_casse_uniq;
                            vm.total_sum_dlc_uniq_chiffre_mobile += element.ca_dlc_casse_mobile_uniq;
                            vm.total_sum_chargement += element.sum_chargement;

                            vm.total_sum_retour += element.sum_retour;
                            vm.total_sum_retour_gajou += element.sum_retour / element.colisage;

                            vm.total_sum_dlc_casse += element.sum_dlc_casse;
                            vm.total_sum_dlc += element.sum_dlc;
                            vm.total_sum_casse += element.sum_casse;
                            vm.total_dlc_casse_client += element.sum_dlc_casse_client;


                            vm.total_sum_gratuite += element.sum_gratuite;

                            vm.total_sum_vente += element.sum_vente;
                            vm.total_sum_vente_hb += element.sum_vente_hb;

                            vm.total_sum_vente_net += element.sum_chargement - element.sum_retour - element.sum_dlc_casse - element.sum_gratuite;
                            vm.total_sum_vente_reel += element.sum_chargement - element.sum_retour;
                            vm.total_sum_vente_gajou += (element.sum_chargement - element.sum_retour) / element.colisage;
                            vm.total_sum_vente_gajou_hb += (element.sum_chargement_uniq_hb - element.sum_retour_uniq_hb) / element.colisage;

                            vm.total_ecart += element.sum_chargement - element.sum_retour - element.sum_vente;

                            // vm.total_reel += (element.sum_chargement_uniq 
                            //     -element.sum_retour_uniq - element.sum_dlc_casse_uniq
                            //     ) * element.prix_ttc_unit;
                            // vm.total_reel += (element.ca_chargement_uniq
                            //     - element.ca_retour_uniq - element.ca_dlc_casse_uniq
                            // );

                            vm.total_reel += (element.ca_chargement_uniq - element.ca_retour_uniq);
                            vm.total_reel_ht += (element.sum_chargement_uniq * element.prix_ht_unit) - (element.sum_retour_uniq * element.prix_ht_unit);
                            // console.log(vm.total_reel);
                            vm.total_facture += element.sum_vente_uniq_sauf_gratuite * element.prix_ttc_unit;
                            vm.total_vente_facture += element.total_vente_facture;
                            vm.total_ca_gratuite_uniq += element.ca_gratuite_uniq;
                            vm.total_ca_casse_uniq += element.ca_casse_uniq;
                            vm.total_ca_dlc_uniq += element.ca_dlc_uniq;


                        }
                    });
                }

                vm.caisse_gratuite = vm.total_ca_gratuite_uniq;
                // console.log(vm.total_sum_dlc_uniq_chiffre_mobile);
                //console.log('gratuite total : ', vm.caisse_gratuite);
                // vm.total_sum_dlc_uniq_chiffre=vm.total_sum_dlc_uniq_chiffre.toString().replace(',', '.');
                vm.total_credit = response.data.total_paiement.total_credit;
                vm.total_credit = (vm.total_credit) ? vm.total_credit : 0;

                vm.total_cheque = response.data.total_paiement.total_cheque;
                vm.total_rec_espece = response.data.total_paiement.total_rec_espece;
                vm.total_rec_cheque = response.data.total_paiement.total_rec_cheque;

                vm.total_net_cheque = response.data.total_paiement.total_net_cheque;
                vm.total_net_cheque = (vm.total_net_cheque) ? vm.total_net_cheque : 0;

                vm.total_espece = response.data.total_paiement.total_espece;
                vm.total_espece = (vm.total_espece) ? vm.total_espece : 0;

                vm.total_avoir = response.data.total_paiement.total_avoir;
                vm.total_ticket = response.data.total_paiement.total_Ticket;
                vm.cummul_caisse = response.data.cummul_caisse.sum_ecart;
                vm.cummul_caisse_date = response.data.cummul_caisse_date.sum_ecart_date;
                vm.nbr_facture = response.data.nbr_facture;
                vm.liste_factures = response.data.list_factures;
                vm.liste_recouvrements = response.data.list_recouvrements;
                // remise
                vm.total_remises = (response.data.sum_entete_commercials.total_remises);
                vm.total_remises = (vm.total_remises) ? vm.total_remises : 0;


                vm.total_tarifs = (response.data.total_tarif_hors_produits.tarifs);
                vm.total_tarifs = (vm.total_tarifs) ? vm.total_tarifs : 0;

                vm.caisse_tarifs = vm.total_tarifs;

                vm.total_net_a_payer = response.data.sum_entete_commercials.total_net_a_payer;
                vm.total_net_a_payer = (vm.total_net_a_payer) ? vm.total_net_a_payer : 0;


                //recette 
                vm.caisse.recette = Number(vm.total_reel);
                vm.caisse_dlc = vm.total_sum_dlc_uniq_chiffre_mobile;
                //console.log('recette', vm.caisse.recette);
                vm.caisse.recette = vm.caisse.recette.toString().replace(/,/g, '');
                vm.caisse.recette = Number(vm.caisse.recette);
                vm.caisseaujourdui = Number(vm.total_espece) +
                    Number(vm.total_net_cheque) +
                    Number(vm.total_ticket);
                vm.show_loader = false;

                /*if($state.params.caisse_code && $state.params.commercial_code){
                    if(vm.caisse_commercial_imp != null && vm.caisse_commercial_imp != undefined){
                        vm.caisse_espece = vm.caisse_commercial_imp.sum_espece;
                        vm.caisse_cheque = vm.caisse_commercial_imp.sum_cheque;
                        vm.caisse_credit = vm.caisse_commercial_imp.sum_credit;
                        vm.caisse_depense = vm.caisse_commercial_imp.sum_depense;
                        vm.caisse_gratuite = vm.caisse_commercial_imp.sum_gratuite;
                        vm.caisse_recouvrement = vm.caisse_commercial_imp.sum_recouvrement;
                        vm.caisse_versement_banque = vm.caisse_commercial_imp.sum_versement_banque;
                        vm.caisse_remise = vm.caisse_commercial_imp.sum_remise;
                    }
                }*/
                if (vm.stat_commercial) {
                    if (vm.caisse_commercial_imp.code != null) {
                        vm.caisse_code = vm.caisse_commercial_imp.code;
                        // vm.stat_caisse='caisse_oui';
                        // vm.stat_caisse_code= vm.caisse_code;
                        /**
                         * check if caisse_code exist dans entetecommercial table
                         */
                        console.log('listfacture', vm.liste_factures);
                        var plucked_caisses = _.pluck(vm.liste_factures, 'caisse_code');
                        console.log('plucked_caisses', plucked_caisses);

                        plucked_caisses = _.filter(plucked_caisses, function (num) { return !_.isNull(num) });
                        console.log('plucked_caisses', plucked_caisses);

                        //console.log('plucked_caisses', plucked_caisses);

                        if (plucked_caisses.length > 0) {
                            var code_caisse_exist = _.find(plucked_caisses, function (num) { return num == vm.caisse_code; });
                            console.log('code_caisse_exist', code_caisse_exist);

                            //console.log('code_caisse_exist', code_caisse_exist);
                            if (!code_caisse_exist) {
                                vm.show_generate_bl = true;
                                console.log('vm.show_generate_bl', vm.show_generate_bl, 'not_exist');

                            }
                        } else {
                            vm.show_generate_bl = true;
                            console.log('vm.show_generate_bl', vm.show_generate_bl, 'not_exist lenth 0');

                        }
                    }
                }



                if (vm.caisse_commercial_imp.cloture != null) { vm.caisse_cloture = vm.caisse_commercial_imp.cloture; } else { vm.caisse_cloture = 0; }
                if (vm.caisse_commercial_imp.sum_espece != null) { vm.caisse_espece = vm.caisse_commercial_imp.sum_espece; }
                if (vm.caisse_commercial_imp.sum_cheque != null) { vm.caisse_cheque = vm.caisse_commercial_imp.sum_cheque; }
                if (vm.caisse_commercial_imp.sum_credit != null) { vm.caisse_credit = vm.caisse_commercial_imp.sum_credit; }
                if (vm.caisse_commercial_imp.sum_depense != null) { vm.caisse_depense = vm.caisse_commercial_imp.sum_depense; }
                if (vm.caisse_commercial_imp.sum_carburant != null) { vm.caisse_carburant = vm.caisse_commercial_imp.sum_carburant; }
                if (vm.caisse_commercial_imp.sum_entretien != null) { vm.caisse_entretien = vm.caisse_commercial_imp.sum_entretien; }
                if (vm.caisse_commercial_imp.sum_gratuite != null) { vm.caisse_gratuite = vm.caisse_commercial_imp.sum_gratuite; }
                if (vm.caisse_commercial_imp.sum_recouvrement != null) { vm.caisse_recouvrement = vm.caisse_commercial_imp.sum_recouvrement; }
                if (vm.caisse_commercial_imp.sum_versement_banque != null) { vm.caisse_versement_banque = vm.caisse_commercial_imp.sum_versement_banque; }
                if (vm.caisse_commercial_imp.sum_remise != null) { vm.caisse_remise = vm.caisse_commercial_imp.sum_remise; }
                if (vm.caisse_commercial_imp.sum_tarifs != null) { vm.caisse_tarifs = vm.caisse_commercial_imp.sum_tarifs; }
                if (vm.caisse_commercial_imp.sum_tarifs != null) { vm.total_tarifs = vm.caisse_commercial_imp.sum_tarifs; }
                if (vm.caisse_commercial_imp.sum_creditgms != null) { vm.caisse_creditgms = vm.caisse_commercial_imp.sum_creditgms; }
                if (vm.caisse_commercial_imp.sum_creditgms != null) { vm.creditgms = vm.caisse_commercial_imp.sum_creditgms; }

                if (vm.caisse_commercial_imp.sum_credit_collectivite != null) { vm.caisse_credit_collectivite = vm.caisse_commercial_imp.sum_credit_collectivite; }
                if (vm.caisse_commercial_imp.sum_credit_collectivite != null) { vm.credit_collectivite = vm.caisse_commercial_imp.sum_credit_collectivite; }

                if (vm.caisse_commercial_imp.sum_credit_collectivite_depot != null) { vm.caisse_credit_collectivite_depot = vm.caisse_commercial_imp.sum_credit_collectivite_depot; }
                if (vm.caisse_commercial_imp.sum_credit_collectivite_depot != null) { vm.credit_collectivite_depot = vm.caisse_commercial_imp.sum_credit_collectivite_depot; }

                if (vm.caisse_commercial_imp.sum_dlc != null) { vm.caisse_dlc = vm.caisse_commercial_imp.sum_dlc; }
                if (vm.caisse_commercial_imp.sum_avoir != null) { vm.caisse_avoir = vm.caisse_commercial_imp.sum_avoir; }
                if (vm.caisse_commercial_imp.sum_ticket != null) { vm.caisse_ticket = vm.caisse_commercial_imp.sum_ticket; }
                if (vm.caisse_commercial_imp.sum_ecart != null) { vm.caisse_ecart = vm.caisse_commercial_imp.sum_ecart; }
                if (vm.caisse_commercial_imp.commentaire != null) { vm.commentaire = vm.caisse_commercial_imp.commentaire; }


                if (reload_caisse) {
                    vm.ajouterCaisse('correction');
                }
                $scope.$apply();

            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    if ($state.params.caisse_code) {
        vm.stat_chargements();
    }
    vm.selectUsers = function () {
        WS.get('users/selectALL', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getRecetteByComm = function (date, commercial) {
        var data = {
            date: date,
            commercial: commercial,
            colisage: vm.stat_colisage,
            caisse: vm.stat_caisse,
            caisse_code: vm.stat_caisse_code
        };
        //console.log(data);
        WS.getData('chargements/getRecetteByComm', data).then(
            (response) => {
                if (response.status == 200) {
                    vm.resrecette = response.data.chargements;
                    vm.total_credit_recette = response.data.total_paiement.total_credit;
                    vm.total_rec_espece_recette = response.data.total_paiement.total_rec_espece;
                    vm.total_rec_cheque_recette = response.data.total_paiement.total_rec_cheque;

                    vm.total_reel_recette = 0;
                    if (vm.resrecette.length > 0) {
                        _.each(vm.resrecette, (element, index) => {
                            vm.total_reel_recette += (element.sum_chargement_uniq - element.sum_retour_uniq - element.sum_dlc_casse_uniq) * element.prix_ttc_unit;

                        });
                        // resultat
                    }

                    const caisse = response.data.caisse;
                    if (caisse != null) {
                        vm.caisse_espece = caisse.espece;
                        vm.caisse_cheque = caisse.cheque;
                        vm.caisse_credit = caisse.credit;
                        vm.caisse_depense = caisse.depense;
                        vm.caisse_carburant = caisse.carburant;
                        vm.caisse_entretien = caisse.entretien;
                        vm.caisse_gratuite = caisse.gratuite;
                        vm.caisse_recouvrement = caisse.recouvrement;
                        vm.caisse_versement_banque = caisse.versement_banque;
                        vm.caisse_remise = caisse.remise;
                        vm.caisse_tarifs = caisse.tarifs;
                        vm.this_caisse = caisse;
                        //console.log(vm.this_caisse);
                    } else {
                        vm.this_caisse.cloture = 0;
                    }


                    $scope.$apply();
                }
            },
            (error) => {
                console.log(error);
            }
        );
        /*WS.get('caisses/recette/' + date + '/' + commercial)
            .then(function(response) {
                if (response.status == 200) {
                    vm.recette = 0;
                    var espece = Number(response.data.espece);
                    var cheque = Number(response.data.cheque);
                    var traite = Number(response.data.traite);
                    var avoir = Number(response.data.avoir);
                    var recette = (espece + cheque + traite) + avoir;
                    vm.recette = recette;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });*/
    };
    vm.ajouterCaisse = function (type_modif = null) {
        // type_modif : si est egale a 'correction' ne prend pas en considération la cloture
        /**
         * remove comma from recette
         */
        console.log('START recalculate caisse', type_modif)
        var recette = vm.caisse.recette;
        recette = Number(recette);
        recette = recette.toString().replace(/,/g, '');
        //console.log(recette);
        /**
         * remove comma from recette
         */
        var journee = Number(vm.caisse_espece) +
            Number(vm.caisse_cheque) +
            Number(vm.caisse_credit) +
            Number(vm.creditgms) +
            Number(vm.credit_collectivite) +
            Number(vm.credit_collectivite_depot) +
            Number(vm.caisse_depense) +
            Number(vm.caisse_carburant) +
            Number(vm.caisse_entretien) +
            Number(vm.caisse_gratuite) +
            Number(vm.caisse_versement_banque) +
            Number(vm.caisse_remise) +
            Number(vm.total_tarifs)
            - Number(vm.caisse_recouvrement) +
            Number(vm.caisse_ticket) +
            Number(vm.caisse_avoir) +
            Number(vm.caisse_dlc);

        // Number(vm.total_sum_dlc_uniq_chiffre) -
        var ecart = Number(recette) + Number(vm.total_sum_dlc_uniq_chiffre) - journee;
        // Number(vm.caisse_espece) -
        // Number(vm.caisse_cheque) -
        // Number(vm.caisse_credit) -
        // Number(vm.creditgms) -
        // Number(vm.caisse_depense) -
        // Number(vm.caisse_carburant) -
        // Number(vm.caisse_entretien) -
        // Number(vm.caisse_gratuite) -
        // Number(vm.caisse_versement_banque) -
        // Number(vm.caisse_remise) -
        // Number(vm.total_tarifs) +
        // Number(vm.caisse_recouvrement) -
        // Number(vm.caisse_ticket) -
        // Number(vm.caisse_avoir) -
        // Number(vm.caisse_dlc);
        ecart = ecart.toFixed(3);
        console.log("ecart ", ecart);
        var data = {
            date: vm.stat_date_debut,
            recette: Number(recette),
            journee: journee,
            espece: vm.caisse_espece,
            cheque: vm.caisse_cheque,
            credit: vm.caisse_credit,
            depense: vm.caisse_depense,
            carburant: vm.caisse_carburant,
            entretien: vm.caisse_entretien,
            gratuite: vm.caisse_gratuite,
            dlc: vm.caisse_dlc,
            avoir: vm.caisse_avoir,
            ticket: vm.caisse_ticket,
            recouvrement: vm.caisse_recouvrement,
            versement_banque: vm.caisse_versement_banque,
            remise: vm.caisse_remise,
            created_at: vm.caisse_date,
            commercial: vm.stat_commercial,
            ecart: Number(ecart),
            creditgms: vm.creditgms,
            credit_collectivite: vm.credit_collectivite,
            credit_collectivite_depot: vm.credit_collectivite_depot,
            commentaire: vm.commentaire,
            caisse: vm.stat_caisse,
            tarifs: vm.total_tarifs,
            caisse_code: vm.caisse_code,
            type_modif: type_modif,
        };
        console.log('dataaaa', data)
        if (data.type_modif == 'correction' && vm.caisse_code) {
            data.caisse_code = vm.caisse_code;
            data.caisse = 'caisse_oui';
        }
        console.log('datacaisseupdate', data, vm.caisse_commercial_imp.code, vm.caisse_code);
        WS.post('caisses', data)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Caisse ajoutée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.printElement = function (header, body, footer) {

        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                    <style>
                                        .noprint{display: none;}.text-center{text-align:center}
                                        th{border:1px solid #ddd!important;}
                                        td{border:1px solid #ddd!important;}
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        if (header) {
            mywindow.document.write(document.getElementById(header).innerHTML);
        }
        mywindow.document.write(document.getElementById(body).innerHTML);
        if (footer) {
            mywindow.document.write(document.getElementById(footer).innerHTML);
        }
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

    vm.stat_updatechargement = function (numero, produit_code, colisage, type, sum_chargement, sum_retour) {
        //console.log(numero, produit_code, type, sum_chargement, sum_retour, vm.stat_commercial);
        if (vm.stat_date_debut != vm.stat_date_fin) {
            swal(
                $translate.instant('Succés'),
                $translate.instant('les dates ne son pas identique!'),
                'warning'
            );
        } else if (vm.stat_commercial == 'null') {
            swal(
                $translate.instant('Succés'),
                $translate.instant('Veuillez bien remplir le champ commercial'),
                'warning'
            );
        } else {

            var data = {
                numero,
                produit_code,
                type,
                sum_chargement,
                sum_retour,
                date: vm.stat_date_debut,
                colisage: colisage,
                with_colisage: vm.stat_colisage,
                commercial_code: vm.stat_commercial
            }

            WS.post('chargements/stat_updatechargement', data).then(
                (response) => {
                    console.log(response);
                },
                (error) => {
                    console.log(error);
                }
            );

        }
    };

    vm.correctionMouvement = function (correction) {
        if (vm.files) {
            correction.file = vm.files[0];
        } else {
            correction.file = '';
        }
        // if (vm.stat_caisse == 'caisse_oui') {
        //     correction.caisse_code = vm.stat_caisse_code;

        // }
        if (vm.caisse_commercial_imp) {
            correction.caisse_code = vm.caisse_commercial_imp.code ? vm.caisse_commercial_imp.code : null;
        }
        correction.soussociete_code = vm.soussociete_code;

        if (vm.soussociete_code) {
            WS.postwithimg('chargements/correction', correction, "POST")
                .then(function (response) {
                    if (response.data.success == true) {

                        // reaffichage stat_chargements
                        if (correction.caisse_code) {
                            vm.stat_chargements(true);
                        } else {
                            swal(
                                $translate.instant('Succés'),
                                $translate.instant('Correction ajoutée avec succées'),
                                'success'
                            ).then(function () {
                                $window.location.reload();
                            }).catch(function () {
                                $window.location.reload();
                            });
                        }

                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant('Veuillez bien remplir les champs!'),
                            'warning'
                        );
                    }

                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal(
                $translate.instant('Warning'),
                $translate.instant('Distributeur Obligatoire!'),
                'success'
            )
        }

    };

    vm.selectProduitChargement = function (type = null) {
        WS.getData('produits/selectProduitChargement', { type: type })
            .then(function (response) {
                vm.produits = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.selectTournees = function (user_code) {
        WS.get('tournees/selectTournees').then(
            (response) => {
                vm.selecttournees = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        )
    }

    vm.init_codejourUndefined = function () {
        if (vm.chargement['ch']['code_jour'] == '') {
            vm.chargement['ch']['code_jour'] = undefined;
            vm.filter_tournee_code = { code_jour: vm.chargement['ch']['code_jour'] };
        } else if (vm.chargement['ch']['code_jour'] == 'Universel') {
            vm.filter_tournee_code = { code: vm.chargement['ch']['code_jour'] };
        } else {
            vm.filter_tournee_code = { code_jour: vm.chargement['ch']['code_jour'] };
        }
    }


    vm.storeChargementType = function (chargement) {
        vm.btn_save = true;
        var produits = [];
        console.log(vm.chargement.ch.produits);

        _.each(vm.chargement.ch.produits, (value, key) => {
            if (value.quantite != undefined && value.quantite != '') {
                produits.push(value);
            }
        });
        chargement.ch.produits = produits;
        //console.log(chargement)
        if (chargement.ch.produits.length > 0) {

            console.log(chargement);

            WS.post('chargements/storeChargementType', chargement)
                .then(function (response) {
                    if (response.data.success) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('chargement ajoutée avec Succès'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    }
                    vm.btn_save = false;

                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal(
                $translate.instant('Attention'),
                $translate.instant('Veuillez bien saisir au moins un produit'),
                'warning'
            )
        }
    }

    vm.getListChargementType = function () {
        WS.get('chargements/getListChargementType').then(
            (response) => {
                vm.listchargement_type = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        );
    };

    vm.appendQuantiteInChargement = function (tournee_code) {
        /**
         * init box;
         */
        vm.c_box = true;
        vm.c_box_title = "Convertir en Box";
        vm.chargement['ch']['colisage'] = false;

        _.each(vm.chargement.ch, (value, key) => {
            if (value instanceof Object) {
                vm.chargement['ch'][key]['quantite'] = undefined;
            }
        });
        WS.get('chargements/getOneChargementType/' + tournee_code).then(
            (response) => {
                _.each(vm.chargement.ch, (value, key) => {
                    if (value instanceof Object) {
                        var i = 0;
                        _.each(response.data, (chargementtype, keycht) => {
                            if (chargementtype.produit_code == value.produit_code) {
                                vm.chargement['ch'][key]['quantite'] = Number(chargementtype.quantite);
                                i++;
                            }

                        });

                        if (i == 0) {
                            vm.chargement['ch'][key]['quantite'] = 0;
                        }
                    }
                });
                vm.total_Casier_chargement();
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        );
    }


    vm.appendQuantiteInChargementType = function (tournee_code) {
        /**
         * init box;
         */
        vm.chargement['ch']['date_debut'] = '';
        vm.chargement['ch']['date_fin'] = '';
        _.each(vm.chargement.ch.produits, (value, key) => {
            if (value instanceof Object) {
                vm.chargement['ch']['produits'][key]['quantite'] = undefined;
            }
        });
        if (tournee_code != '' && tournee_code != undefined) {
            WS.get('chargements/getOneChargementType/' + tournee_code).then(
                (response) => {
                    if (response.data.length > 0) {
                        var first_obj = response.data[0];
                        vm.chargement['ch']['date_debut'] = first_obj.date_debut;
                        vm.chargement['ch']['date_fin'] = first_obj.date_fin;
                        _.each(vm.chargement.ch.produits, (value, key) => {
                            if (value instanceof Object) {
                                _.each(response.data, (chargementtype, keycht) => {
                                    if (chargementtype.produit_code == value.produit_code) {
                                        vm.chargement['ch']['produits'][key]['quantite'] = Number(chargementtype.quantite);
                                        //console.log(vm.chargement['ch']['produits'][key]['quantite'])

                                    }
                                });
                            }
                        });
                    }
                    $scope.$apply();
                },
                (error) => {
                    console.log(error)
                }
            );
        }
    }

    vm.convertirEnBox = function (chargement) {
        vm.show_loader = true;
        if (vm.multi_camion == false) {
            if (vm.c_box == true) {
                _.each(chargement.ch, (value, key) => {
                    if (value instanceof Object) {
                        vm.chargement['ch'][key]['quantite'] = Number(vm.chargement['ch'][key]['quantite']) / Number(value.colisage);
                    }

                });
                // cocher colisage
            } else {
                _.each(chargement.ch, (value, key) => {
                    if (value instanceof Object) {
                        vm.chargement['ch'][key]['quantite'] = Number(vm.chargement['ch'][key]['quantite']) * Number(value.colisage);
                    }

                });
                // decocher colisage
            }
            if (vm.c_box == false) { vm.c_box_title = "Convertir en Box"; } else { vm.c_box_title = "Convertir en piéce"; }
            vm.chargement['ch']['colisage'] = vm.c_box;
            vm.c_box = !vm.c_box;
        } else {
            ///////////////////////////// Multi camion \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
            //console.log(vm.chargement.multi_camion);
            if (vm.c_box == true) {
                _.each(vm.chargement.multi_camion, (depots, key_dep) => {
                    _.each(depots, (prod, idprod) => {
                        if (vm.chargement.multi_camion[key_dep][idprod]['quantite'] != undefined &&
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] != null &&
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] != ''
                        ) {
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] = Number(vm.chargement.multi_camion[key_dep][idprod]['quantite']) /
                                Number(vm.chargement.multi_camion[key_dep][idprod]['colisage']);
                            //vm.chargement.multi_camion[key_dep][idprod]['quantite'] = vm.chargement.multi_camion[key_dep][idprod]['quantite'].toString().replace(/,/g, '.'); 
                            //vm.chargement.multi_camion[key_dep][idprod]['quantite'] = Number(vm.chargement.multi_camion[key_dep][idprod]['quantite']); 
                        }
                    });
                });
                // cocher colisage
            } else {
                _.each(vm.chargement.multi_camion, (depots, key_dep) => {
                    _.each(depots, (prod, idprod) => {
                        if (vm.chargement.multi_camion[key_dep][idprod]['quantite'] != undefined &&
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] != null &&
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] != ''
                        ) {
                            vm.chargement.multi_camion[key_dep][idprod]['quantite'] = Number(vm.chargement.multi_camion[key_dep][idprod]['quantite']) *
                                Number(vm.chargement.multi_camion[key_dep][idprod]['colisage']);
                        }
                    });
                });
                // decocher colisage
            }
            if (vm.c_box == false) { vm.c_box_title = $translate.instant("Convertir en Box"); } else { vm.c_box_title = $translate.instant("Convertir en piéce"); }
            vm.chargement['ch']['colisage'] = vm.c_box;
            vm.c_box = !vm.c_box;
        }
        vm.show_loader = false;



    };

    /**
     * export axcel bl+avoir stat-chargements
     */
    vm.exportExcel = function (withavoir = null) {
        var bldate = {
            date_debut: vm.stat_date_debut,
            date_fin: vm.stat_date_fin,
            commercial: vm.stat_commercial
        }
        if (bldate == undefined || bldate == {}) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
            return;
        } else if (bldate.commercial == undefined || bldate.commercial == '') {
            swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ commercial'), 'error');
            return;
        } else {
            if (withavoir) {
                //withavoir = 'avoir';
                var url = 'bl/getByDateExport/' + bldate.date_debut + '/' + bldate.date_fin + '/bl/avoir';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/' + bldate.commercial;
                }
            } else {
                var url = 'bl/getByDateExport/' + bldate.date_debut + '/' + bldate.date_fin + '/bl';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/null/' + bldate.commercial;
                }
            }

            WS.get(url)
                .then(function (response) {
                    if (response.data.length > 0) {
                        var date_debut = response.data[response.data.length - 1].date_creation;
                        var date_fin = response.data[0].date_creation;
                        var filename = 'Bl+avoir' + date_debut + '-_-_-' + date_fin;
                    } else {
                        var filename = 'Bl+avoir';

                    }
                    response.data.forEach(function (element, index) {
                        response.data[index].type = $translate.instant(element.type);
                    });

                    var stylecsv = {
                        sheetid: filename,
                        headers: true,
                        caption: {
                            title: filename,
                            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                        },
                        //style:'background:#fff',
                        column: {
                            style: 'font-size:20px;background:#d1d1d1;'
                        },
                        columns: [
                            { columnid: 'code', title: $translate.instant('Code') },
                            { columnid: 'produit', title: $translate.instant('Produit') },
                            { columnid: 'marque', title: $translate.instant('Marque') },
                            { columnid: 'gamme', title: $translate.instant('Gamme') },
                            { columnid: 'famille', title: $translate.instant('Famille') },
                            { columnid: 'sousfamille', title: $translate.instant('Sous Famille') },
                            { columnid: 'entetecommercial_code', title: $translate.instant('BL_N°') },
                            { columnid: 'quantite', title: $translate.instant('Quantite') },
                            { columnid: 'pu_ht', title: $translate.instant('Prix HT') },
                            { columnid: 'pu_ttc', title: $translate.instant('Prix TTC') },
                            { columnid: 'p_tva', title: $translate.instant('tva') },
                            { columnid: 'taux_tva', title: $translate.instant('Taux Tva') },
                            { columnid: 'total', title: $translate.instant('Total') },
                            { columnid: 'total_net', title: $translate.instant('Total Net') },
                            { columnid: 'date_creation', title: $translate.instant('date') },
                            { columnid: 'taux_remise', title: $translate.instant('Taux de Remise') },
                            { columnid: 'remise', title: $translate.instant('Remise') },
                            { columnid: 'type', title: $translate.instant('Type') },
                            { columnid: 'commercial', title: $translate.instant('Commercial') },
                            { columnid: 'client', title: $translate.instant('Client') },
                            { columnid: 'magasin', title: $translate.instant('Magasin') },
                            { columnid: 'routing_code', title: $translate.instant('Route') },
                            { columnid: 'localite', title: $translate.instant('Localite') },
                            { columnid: 'region', title: $translate.instant('Region') },
                            { columnid: 'gouvernorat', title: $translate.instant('Gouvernorat') },
                            { columnid: 'secteur', title: $translate.instant('Secteur') },
                            { columnid: 'zone', title: $translate.instant('Zone') }
                        ],
                        row: {
                            style: function (sheet, row, rowidx) {
                                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                            }
                        }
                    };
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                    //alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };

    vm.printElementChargement = function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Chargement </title>
                                    <style>.noprint{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th.text-center{border:1px solid #ddd!important; font-size:12px!important;}
                                        td.text-center{border:1px solid #ddd!important; font-size:12px!important;}
                                        h4,h5{margin: 4px;}
                                    </style>
                                `);
        mywindow.document.write('</head><body>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };



    vm.printElementRetour = function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Retour </title>
                                    <style>.noprint{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th.text-center{border:1px solid #ddd!important; font-size:12px!important;}
                                        td.text-center{border:1px solid #ddd!important; font-size:12px!important;}
                                        h4,h5{margin: 4px;}
                                    </style>
                                `);
        mywindow.document.write('</head><body>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };



    vm.DeleteDoubleMvt = function () {
        vm.show_loader = true;
        WS.delete('chargements/DeleteDoubleMvt').then(
            function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('mise a jour avec succé.'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('erreur de traitement!!'),
                        'warning'
                    );
                }
                vm.show_loader = false;
                $scope.$apply();
            },
            function () {
                swal(
                    $translate.instant('Attention!'),
                    $translate.instant('erreur de traitement!!'),
                    'warning'
                );
            }
        );
    };




    vm.stat_prods_categParDate = function () {
        const data = {
            // distributeur_code: vm.details_soussociete_code,
            date_debut: vm.details_date_debut,
            date_fin: vm.details_date_fin,
            commercial: vm.details_commercial,
            categorie: vm.details_categorie,
            colisage: vm.details_colisage
        };
        if (vm.group_date) {
            data.group_date = vm.group_date;
        }
        WS.getData('chargements/stat_prods_categParDate', data)
            .then(function (response) {

                if (response.data.success) {
                    $window.location.href = response.data.url;
                }

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.stat_prods_categ = function () {
        const data = {
            // distributeur_code: vm.details_soussociete_code,
            date_debut: vm.details_date_debut,
            date_fin: vm.details_date_fin,
            commercial: vm.details_commercial,
            categorie: vm.details_categorie,
            colisage: vm.details_colisage
        };
        if (vm.group_date) {
            data.group_date = vm.group_date;
        }
        WS.getData('chargements/stat_prods_categ', data)
            .then(function (response) {
                vm.list_prods = response.data.details;
                vm.code_elastic_ste = response.data.soussociete ? response.data.soussociete.code_elastic : 'Nomadis';
                //vm.prices_prods_categ = response.data.prices;
                // _.each(vm.list_prods, (element, index) => {
                //     _.each(vm.prices_prods_categ, (price) => {
                //         if(price.produit_code == element.code){
                //             vm.list_prods[index].prix_ttc = price.prix_ttc;
                //             //vm.list_prods[index].prix_ttc_unit = price.prix_ttc_unit;
                //         }
                //     });
                // });
                // calcul total
                vm.total_prods = {};
                vm.total_prods.sum_stock = 0;

                vm.total_prods.sum_chargement_uniq = 0;
                vm.total_prods.sum_retour_uniq = 0;
                vm.total_prods.sum_chargement = 0;
                vm.total_prods.sum_retour = 0;
                vm.total_prods.sum_casse = 0;
                vm.total_prods.sum_dlc = 0;
                vm.total_prods.sum_gratuite = 0;
                vm.total_prods.sum_vente = 0;
                vm.total_prods.sum_total_ttc = 0;
                vm.total_prods.total_marge = 0;
                _.each(vm.list_prods, (element, index) => {

                    _.each(response.data.stocks, stock => {
                        if (stock.code == element.code) {
                            vm.list_prods[index].stock = stock.stock;
                            vm.list_prods[index].stock_uniq = stock.stock_uniq;
                        }
                    })
                    vm.total_prods.sum_stock += Number(element.stock);
                    vm.total_prods.sum_chargement_uniq += Number(element.chargement_uniq);
                    vm.total_prods.sum_retour_uniq += Number(element.retour_uniq);
                    vm.total_prods.sum_chargement += Number(element.chargement);
                    vm.total_prods.sum_retour += Number(element.retour);
                    vm.total_prods.sum_casse += Number(element.casse);
                    vm.total_prods.sum_dlc += Number(element.dlc);
                    vm.total_prods.sum_gratuite += Number(element.gratuite);
                    vm.total_prods.total_marge += (Number(element.chargement) - Number(element.retour) - Number(element.casse) - Number(element.dlc) - Number(element.gratuite)) * element.marge;

                    vm.total_prods.sum_vente += Number(element.chargement) - Number(element.retour) - Number(element.dlc) - Number(element.casse) - Number(element.gratuite);

                    vm.total_prods.sum_total_ttc += ((element.ca_chargement_uniq) - (element.ca_retour_uniq) -
                        (element.ca_dlc_uniq) - (element.ca_casse_uniq) -
                        (element.ca_gratuite_uniq));
                });

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.export_rapport_stats_categ = function (tableId) {
        const data = {
            date_debut: vm.details_date_debut,
            date_fin: vm.details_date_fin,
            commercial: vm.details_commercial,
            categorie: vm.details_categorie,
            colisage: vm.details_colisage
        };
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = data.date_debut + '_||_' + data.date_fin + '_||_' + data.commercial +
            '_||_' + data.categorie + '_||_' + data.colisage;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.export_rapport_stats_commercials = function (tableId) {

        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = 'Rapport_stats_Commercial||_' + vm.camion.date_debut +
            '_||_' + vm.camion.date_debut;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };


    vm.printChargementType = function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Chargement Type </title>
                                    <style>.noprint{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th{border:1px solid #ddd!important; font-size:13px;}
                                        td{border:1px solid #ddd!important; font-size:13px;}
                                        h4,h5{margin: 4px;}
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        //mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };


    vm.printChargement = function (elem) {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Chargement </title>
                                    <style>.noprint{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th{border:1px solid #ddd!important; font-size:10px;}
                                        td{border:1px solid #ddd!important; font-size:10px;}
                                        h4,h5{margin: 4px;}
                                        
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        //mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    };

    vm.selectedCategorieList = function () {
        var base_url = 'produits';
        if (vm.camion.categorie == 'p') {
            base_url = 'produits';
        } else if (vm.camion.categorie == 'g') {
            base_url = 'gammes';
        } else if (vm.camion.categorie == 'f') {
            base_url = 'familles';
        } else if (vm.camion.categorie == 'sf') {
            base_url = 'sousfamilles';
        }
        base_url += '/select';
        WS.get(base_url).then(
            (response) => {
                vm.selected_categories = response.data;
                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        );

    };

    vm.stat_camion_categ = function () {
        //console.log(vm.camion);
        WS.getData('chargements/stat_camion_categ', vm.camion).then(
            (response) => {
                vm.details_categ_stats = response.data;
                vm.t_qte_lait = 0;
                vm.t_qte_pots = 0;
                vm.t_qte_gajou_net = 0;
                vm.t_qte_gajou_reel = 0;

                vm.t_sum_chargement = 0;
                vm.t_sum_dlc = 0;
                vm.t_sum_casse = 0;
                vm.t_sum_retour = 0;
                vm.t_sum_gratuite = 0;

                vm.t_ca_sum_vente = 0;
                vm.t_ca_sum_chargement = 0;
                vm.t_ca_sum_retour = 0;
                vm.t_ca_sum_casse = 0;
                vm.t_ca_sum_dlc = 0;
                vm.t_ca_sum_gratuite = 0;
                vm.t_ca_espece = 0;
                vm.t_ca_credit = 0;
                vm.t_ca_cheque = 0;
                vm.t_ca_depense = 0;
                vm.t_ca_gratuite = 0;
                vm.t_ca_recouvrement = 0;
                vm.t_ca_remise = 0;
                vm.t_ca_tarifs_speciales = 0;
                vm.t_ca_versement_banque = 0;
                vm.t_ca_journee_total = 0;
                vm.t_ca_recette = 0;
                vm.t_ca_commission = 0;
                vm.t_ca_ecart_caisse = 0;

                _.each(vm.details_categ_stats, (el, index) => {
                    //console.log(el.qte_gajou,el.colisage, vm.t_qte_gajou);
                    vm.t_qte_lait += (el.qte_lait != null) ? Number(el.qte_lait) : 0;
                    vm.t_qte_pots += (el.qte_pots != null) ? Number(el.qte_pots) : 0;
                    //var t_i_qte_gajou = 0;
                    //t_i_qte_gajou += (el.qte_gajou != null) ? Number(el.qte_gajou) / Number(el.colisage) : 0;
                    //vm.details_categ_stats[index].qte_gajou_rounded = Math.round(t_i_qte_gajou);
                    var t_qte_gajou_net = (el.qte_gajou_net != null) ? Number(el.qte_gajou_net) : 0;
                    vm.t_qte_gajou_net += Math.round(t_qte_gajou_net);

                    var t_qte_gajou_reel = (el.qte_gajou_reel != null) ? Number(el.qte_gajou_reel) : 0;
                    vm.t_qte_gajou_reel += Math.round(t_qte_gajou_reel);

                    vm.t_ca_sum_vente += (el.ca_sum_vente != null) ? Number(el.ca_sum_vente) : 0;

                    vm.t_sum_chargement += (el.sum_chargement != null) ? Number(el.sum_chargement) : 0;
                    vm.t_sum_dlc += (el.sum_dlc != null) ? Number(el.sum_dlc) : 0;
                    vm.t_sum_casse += (el.sum_casse != null) ? Number(el.sum_casse) : 0;
                    vm.t_sum_retour += (el.sum_retour != null) ? Number(el.sum_retour) : 0;
                    vm.t_sum_gratuite += (el.sum_gratuite != null) ? Number(el.sum_gratuite) : 0;

                    vm.t_ca_commission += (el.sum_chargement_marge - el.sum_retour_marge - el.sum_casse_marge - el.sum_dlc_marge - el.sum_gratuite_marge);
                    vm.t_ca_sum_chargement += (el.ca_sum_chargement != null) ? Number(el.ca_sum_chargement) : 0;
                    vm.t_ca_sum_retour += (el.ca_sum_retour != null) ? Number(el.ca_sum_retour) : 0;
                    vm.t_ca_sum_casse += (el.ca_sum_casse != null) ? Number(el.ca_sum_casse) : 0;
                    vm.t_ca_sum_dlc += (el.ca_sum_dlc != null) ? Number(el.ca_sum_dlc) : 0;
                    vm.t_ca_sum_gratuite += (el.qte_lca_sum_gratuiteait != null) ? Number(el.ca_sum_gratuite) : 0;
                    vm.t_ca_espece += (el.ca_espece != null) ? Number(el.ca_espece) : 0;
                    vm.t_ca_credit += (el.ca_credit != null) ? Number(el.ca_credit) : 0;
                    vm.t_ca_cheque += (el.ca_cheque != null) ? Number(el.ca_cheque) : 0;
                    vm.t_ca_recouvrement += (el.ca_recouvrement != null) ? Number(el.ca_recouvrement) : 0;
                    vm.t_ca_remise += (el.ca_remise != null) ? Number(el.ca_remise) : 0;
                    vm.t_ca_tarifs_speciales += (el.ca_tarifs_special != null) ? Number(el.ca_tarifs_special) : 0;
                    vm.t_ca_versement_banque += (el.ca_versement_banque != null) ? Number(el.ca_versement_banque) : 0;
                    vm.t_ca_depense += (el.ca_depense != null) ? Number(el.ca_depense) : 0;
                    vm.t_ca_gratuite += (el.ca_gratuite != null) ? Number(el.ca_gratuite) : 0;
                    vm.t_ca_journee_total += (el.ca_journee_total != null) ? Number(el.ca_journee_total) : 0;
                    vm.t_ca_recette += (el.ca_recette != null) ? Number(el.ca_recette) : 0;
                    vm.t_ca_ecart_caisse += (el.ca_ecart_caisse != null) ? Number(el.ca_ecart_caisse) : 0;
                });

                vm.t_qte_gajou = Math.round(vm.t_qte_gajou);

                $scope.$apply();
            },
            (error) => {
                console.log(error)
            }
        );
    }

    vm.exporttableHtml = function (tableId) {
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = "";
        if (vm.stat_date_debut && vm.stat_date_fin && vm.userinfo.user) {
            title += vm.stat_date_debut + '_||_' + vm.stat_date_fin + '_||_' + vm.userinfo.user;
        } else if (vm.stat_date_debut && vm.stat_date_fin && !vm.userinfo.user) {
            title += vm.stat_date_debut + '_||_' + vm.stat_date_fin + '_||_ALLCommercials';
        } else {
            title = new Date().getTime();
        }


        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.change_qte_by_type = function (type, commercial, retour_date_debut = null, retour_date_fin = null) {
        if (type != 'chargement') {
            vm.chargement['ch']['retour_hier'] = false;
        }

        if (!$state.params.numero) {
            if (type == 'retour') {
                _.each(vm.produits, (el, ind) => {
                    _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], {
                        quantite: 0, retour_hier_p: 0,
                        demande_chargement: 0,
                        quantite_dlc: 0,
                        quantite_casse: 0
                    });
                });
                //if(retour_date_debut && retour_date_fin){
                WS.getData('chargements/change_qte_by_type', { type, commercial, retour_date_debut, retour_date_fin }).then(
                    (response) => {
                        if (response.data.length > 0) {

                            _.each(vm.produits, (p, index) => {
                                //if(p instanceof Object){

                                _.each(response.data, (el, ind) => {
                                    if (el.produit_code == p.code) {
                                        _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], {
                                            quantite: el.quantite_retour,
                                            quantite_dlc: el.quantite_dlc,
                                            quantite_casse: el.quantite_casse
                                        });
                                    }
                                });
                                //}
                            });
                        }
                        $scope.$apply();
                    },
                    (error) => {
                        console.log(error)
                    }
                );
                //}

            } else {
                _.each(vm.produits, (p, index) => {
                    _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { quantite: 0, retour_hier_p: 0 });
                });
            }eR
        }else{
            console.log('-_-_-_-_-_-_-_-_-_-_-_ ' +$state.params.numero+ '-_-_-_-_-_-_-_-__-')
        }

    }

    //check for authorization_stock
    vm.check_auth_stock = function () {
        WS.getScache('societes')
            .then(function (response) {
                vm.societes = response.data[0];
                var contrat_code = vm.societes.contrat;
                /*console.log(response);
                console.log(contrat_code);*/
                $http({
                    method: 'GET',
                    url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                    cache: false
                }).then(function successCallback(response) {

                    if ($.isEmptyObject(response.data) == true) {
                        vm.errorCheckContrat = $translate.instant(`Erreur de vérification de compte, veuillez contacter l'admin.`);
                    } else {
                        if (response.data.isactif == "1") {
                            vm.authorization_stock = response.data.authorization_stock;
                        } else {
                            vm.expiration = true;
                            vm.errorCheckContrat = $translate.instant(`Votre compte a été désactivée.`);
                        }
                    }
                }, function errorCallback(response) {

                });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.new_chargement_from_bilan = function () {
        var data = {
            chargement: vm.chargements_stats,
            date: vm.new_chargement.date,
            commercial: vm.stat_commercial,
            envoi: vm.new_chargement.envoi
        };
        //console.log(data);
        WS.post('chargements/new_chargement_from_bilan', data)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('chargement Ajoutée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    }).catch(function () {
                        $window.location.reload();
                    });
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.check_class_red_green = function (colisage, stk_quantite, id_prod, quota) {


        if (vm.chargement.ch.type == 'chargement') {

            var quantite = vm.chargement['ch'][id_prod]['quantite'] ? Number(vm.chargement['ch'][id_prod]['quantite']) : 0;
            var box_quantite = vm.chargement['ch'][id_prod]['box_quantite'] ? Number(vm.chargement['ch'][id_prod]['box_quantite']) : 0;
            var retour_hier = vm.chargement['ch'][id_prod]['retour_hier_p'] ? Number(vm.chargement['ch'][id_prod]['retour_hier_p']) : 0;
            var demande_chargement = vm.chargement['ch'][id_prod]['demande_chargement_p'] ? Number(vm.chargement['ch'][id_prod]['demande_chargement_p']) : 0;
            var colisage = vm.chargement['ch']['colisage'] ? colisage : 1;

            quantite = vm.chargement['ch']['colisage'] ? quantite + (colisage * box_quantite) : quantite;
            //console.log(quantite)
            //dlc && casse
            var quantite_dlc = vm.chargement['ch'][id_prod]['quantite_dlc'] ? Number(vm.chargement['ch'][id_prod]['quantite_dlc']) : 0;
            var quantite_casse = vm.chargement['ch'][id_prod]['quantite_casse'] ? Number(vm.chargement['ch'][id_prod]['quantite_casse']) : 0;

            if (vm.parametrages.use_quota == 1 && quota >= 0) {
                if (
                    retour_hier + demande_chargement + quantite > stk_quantite || quantite < 0 ||
                    (retour_hier + demande_chargement) + quantite > quota
                ) {
                    return { red_clr: true };
                } else {
                    return { green_clr: true };
                }
            } else {
                if (
                    retour_hier + demande_chargement + quantite > stk_quantite || quantite < 0
                ) {
                    return { red_clr: true };
                } else {
                    return { green_clr: true };
                }
            }
        } else if (vm.chargement.ch.type == 'retour') {
            if (quantite > 0) {
                return { green_clr: true }
            }
            if (quantite_dlc > 0) {
                return { green_clr: true }
            }
            if (quantite_casse > 0) {
                return { green_clr: true }
            }
        } else if (vm.chargement.ch.type == 'inventaire') {
            if (quantite >= 0) {
                console.log(quantite, { green_clr: true })
                return { green_clr: true }
            }
        }

    };


    vm.get_retour_hier_by_com = function () {
        //console.log(vm.chargement.ch.retour_hier, retour_date_debut, retour_date_fin);

        if (vm.multi_camion == false) {


            if (vm.chargement.ch.retour_hier) {
                if (vm.chargement['ch']['commercial'] == '') {
                    vm.chargement.ch.retour_hier = false;
                    swal(
                        $translate.instant('Oups'),
                        $translate.instant('Veuillez bien saisir le commercial!'),
                        'warning'
                    );
                } else {
                    WS.getData('chargements/getLastRetour', { commercial: vm.chargement.ch.commercial }).then(
                        function (response) {
                            if (response.data.success) {
                                //if(response.data.data.length>0){
                                _.each(vm.produits, (p, index) => {
                                    var i = 0;
                                    _.each(response.data.data, (res, ind) => {
                                        if (p.code == res.produit_code) {
                                            _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { quantite: 0, retour_hier_p: res.quantite });
                                            i++;
                                        }
                                    });
                                    if (i == 0) {
                                        _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { quantite: 0, retour_hier_p: 0 });
                                    }
                                });
                                //}
                                /**
                                 * init total casier et valeur
                                 */
                                vm.total_Casier_chargement();
                            }

                            $scope.$apply();
                        },
                        function (error) {
                            console.log('error : ->', error);
                        }
                    );
                }
            } else {
                _.each(vm.produits, (el, ind) => {
                    //_.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite: 0, retour_hier_p: 0 });
                    _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { retour_hier_p: 0 });
                });
                /**
                 * init total casier et valeur
                 */
                console.log('reinit total_casier sans retour hier')
                vm.total_Casier_chargement();
            }
        }

        if (vm.chargement['ch']['retour_hier'] && vm.multi_camion) {

            /**
             * get retour hier for all users
             */
            WS.getData('chargements/getAllLastRetour').then(
                function (response) {
                    if (response.data.success) {
                        //if(response.data.data.length>0){
                        // _.each(vm.produits, (p, index) => {
                        //     var i = 0;
                        //     _.each(response.data.data, (res, ind) => {
                        //         if (p.code == res.produit_code) {
                        //             _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { quantite: 0, retour_hier_p: res.quantite });
                        //             i++;
                        //         }
                        //     });
                        //     if (i == 0) {
                        //         _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { quantite: 0, retour_hier_p: 0 });
                        //     }
                        // });
                        //}
                        /**
                         * init total casier et valeur
                         */

                        _.each(response.data.data, (res, ind) => {
                            _.extend(vm.chargement['multi_camion'][res.depot_code][res.id], vm.chargement['multi_camion'][res.depot_code][res.id], { quantite: res.quantite, demande_chargement_p: 0 });
                        });


                        vm.total_Casier_chargement();
                    }

                    $scope.$apply();
                },
                function (error) {
                    console.log('error : ->', error);
                }
            );
        }

    }

    vm.get_retourI_hier_by_com = function () {
        //console.log(vm.chargement.ch.retour_hier, retour_date_debut, retour_date_fin);

        if (vm.multi_camion == false) {


            if (vm.chargement.ch.retourI_hier) {
                if (vm.chargement['ch']['commercial'] == '') {
                    vm.chargement.ch.retourI_hier = false;
                    swal(
                        $translate.instant('Oups'),
                        $translate.instant('Veuillez bien saisir le commercial!'),
                        'warning'
                    );
                } else {
                    WS.getData('chargements/getLastRetourI', { commercial: vm.chargement.ch.commercial }).then(
                        function (response) {
                            if (response.data.success) {
                                //if(response.data.data.length>0){
                                _.each(vm.produits, (p, index) => {
                                    var i = 0;
                                    _.each(response.data.data, (res, ind) => {
                                        if (p.code == res.produit_code) {
                                            _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { retourI_hier_p: res.quantite });
                                            i++;
                                        }
                                    });
                                    if (i == 0) {
                                        _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { retourI_hier_p: 0 });
                                    }
                                });
                                //}
                                /**
                                 * init total casier et valeur
                                 */
                                vm.total_Casier_chargement();
                            }

                            $scope.$apply();
                        },
                        function (error) {
                            console.log('error : ->', error);
                        }
                    );
                }
            } else {
                _.each(vm.produits, (el, ind) => {
                    //_.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite: 0, retour_hier_p: 0 });
                    _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { retourI_hier_p: 0 });
                });
                /**
                 * init total casier et valeur
                 */
                console.log('reinit total_casier sans retour hier')
                vm.total_Casier_chargement();
            }
        }

        if (vm.chargement['ch']['retourI_hier'] && vm.multi_camion) {

            /**
             * get retour hier for all users
             */
            WS.getData('chargements/getAllLastRetour').then(
                function (response) {
                    if (response.data.success) {

                        /**
                         * init total casier et valeur
                         */

                        _.each(response.data.data, (res, ind) => {
                            _.extend(vm.chargement['multi_camion'][res.depot_code][res.id], vm.chargement['multi_camion'][res.depot_code][res.id], { quantite: res.quantite, demande_chargement_p: 0 });
                        });


                        vm.total_Casier_chargement();
                    }

                    $scope.$apply();
                },
                function (error) {
                    console.log('error : ->', error);
                }
            );
        }

    }



    vm.get_demande_chargement_by_com = function () {
        //console.log(vm.chargement.ch.retour_hier, retour_date_debut, retour_date_fin);
        if (vm.multi_camion == false) {
            if (vm.chargement.ch.demande_chargement) {
                if (vm.chargement['ch']['commercial'] == '') {
                    vm.chargement.ch.demande_chargement = false;
                    swal(
                        $translate.instant('Oups'),
                        $translate.instant('Veuillez bien saisir le commercial!'),
                        'warning'
                    );
                } else {
                    if (vm.chargement['ch']['type'] == 'chargement') {
                        WS.getData('chargements/getDemandeChargement', { commercial: vm.chargement.ch.commercial }).then(
                            function (response) {
                                if (response.data.success) {
                                    //if(response.data.data.length>0){
                                    _.each(vm.produits, (p, index) => {
                                        var i = 0;
                                        _.each(response.data.data, (res, ind) => {
                                            if (p.code == res.produit_code) {
                                                _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { quantite: 0, demande_chargement_p: res.quantite });
                                                i++;
                                            }
                                        });
                                        if (i == 0) {
                                            _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], { quantite: 0, demande_chargement_p: 0 });
                                        }
                                    });
                                    //}

                                    /**
                                     * init total casier et valeur
                                     */
                                    vm.total_Casier_chargement();
                                }

                                $scope.$apply();
                            },
                            function (error) {
                                console.log('error : ->', error);
                            }
                        );
                    }

                }
            } else {
                if(!$state.params.numero){
                    _.each(vm.produits, (el, ind) => {
                        //_.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite: 0, demande_chargement: 0 });
                        _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { demande_chargement_p: 0, quantite: 0 });
                    });
                }
               
                /**
                 * init total casier et valeur
                 */
                vm.total_Casier_chargement();
            }
        }

        if (vm.chargement['ch']['demande_chargement'] && vm.multi_camion) {
            WS.getData('chargements/getAllDemandeChargement').then(
                function (response) {
                    if (response.data.success) {
                        //if(response.data.data.length>0){
                        // _.each(vm.produits, (p, index) => {
                        //     var i = 0;
                        //     _.each(response.data.data, (res, ind) => {
                        //         if (p.code == res.produit_code) {
                        //             vm.chargement['multi_camion'][res.depot_code][p.id]['quantite']
                        //             _.extend(vm.chargement['multi_camion'][res.depot_code][p.id], vm.chargement['multi_camion'][res.depot_code][p.id], { quantite: res.quantite, demande_chargement_p: 0 });
                        //             i++;
                        //         }
                        //     });
                        //     if (i == 0) {
                        //         _.extend(vm.chargement['multi_camion'][res.depot_code][p.id], vm.chargement['multi_camion'][res.depot_code][p.id], { quantite: 0, demande_chargement_p: 0 });
                        //     }
                        // });
                        //}
                        _.each(response.data.data, (res, ind) => {
                            _.extend(vm.chargement['multi_camion'][res.depot_code][res.id], vm.chargement['multi_camion'][res.depot_code][res.id], { quantite: res.quantite, demande_chargement_p: 0 });
                        });
                        /**
                         * init total casier et valeur
                         */
                        vm.total_Casier_chargement();
                    }

                    $scope.$apply();
                },
                function (error) {
                    console.log('error : ->', error);
                }
            );
        }

    }




    vm.selectDepotWithUser = function () {
        WS.get('depots/selectDepotWithUser', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectdepots = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.change_commercials_value = function () {
        if (vm.chargement.ch.commercial == "TOUT_CAMIONS") {
            vm.multi_camion = true;
            vm.chargement['ch']['retour_hier'] = false;
            vm.chargement['ch']['type'] = 'chargement';
            vm.commercial_selected = undefined;
        } else {
            vm.multi_camion = false;
            //vm.commercial_selected = _.find(vm.commercials, function(num){ return num.code == vm.chargement.ch.commercial; });
            WS.get('users/byCode/' + vm.chargement.ch.commercial)
                .then(function (response) {
                    vm.commercial_selected = response.data;
                    //console.log(vm.commercial_selected);
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    }



    vm.updateTotalSortie = function (depot_code, prod_id) {
        vm.totalsortiecamion[prod_id] = 0;
        vm.allchargementsTotal = 0;
        _.each(vm.chargement.inverse_multi_camion[prod_id], (el, ind) => {
            if (typeof el === 'object') {
                vm.totalsortiecamion[prod_id] += el.quantite;
            }
        });
        vm.chargement['inverse_multi_camion'][prod_id]['total_chargement'] = vm.totalsortiecamion[prod_id];
        _.each(vm.produits, (prod, ind) => {
            if (typeof vm.totalsortiecamion[prod.id] == 'number') {
                vm.allchargementsTotal += vm.totalsortiecamion[prod.id];
            }
        });
    };



    vm.recalculerCaisse = function (caisse_code) {
        var new_recette = vm.total_reel - vm.total_credit - vm.total_cheque + vm.total_rec_espece + vm.total_rec_cheque - vm.total_remises - vm.total_tarifs;
        var data = { caisse_code, new_recette }

        WS.post('caisses/recalculerCaisse', data).then(
            response => {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Caisse modifiée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    }).catch(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        $translate.instant('Oups'),
                        $translate.instant(response.data.message),
                        'warning'
                    )
                }
            },
            error => {

            }
        )
    }


    /**
     * detect keys for navigation vm.multi_camion
     */
    /* $(document).ready(function() {
         $("body").on('keyup', function(e) {
             var element = $('input:focus').val();
         	
 
             //bas
             var keycode = (event.keyCode ? event.keyCode : event.which);
             //var keyCode = (window.event) ? e.which : e.keyCode;
             console.log(keycode);
             if(keycode == 40 || keycode == 38){
                 //var ret_hier = $('input:focus').data('retour_hier_index');
 
             }
             
         });
     });*/

    vm.init_caisse_code = function () {
        if (vm.stat_caisse == "caisse_non") {
            vm.stat_caisse_code = '';
        }
    };


    vm.getUserByTourneeCode = function () {

        WS.getData('chargements/getUserByTourneeCode', { tournee_code: vm.chargement.ch.tournee_code })
            .then(function (response) {
                vm.infochargtype = response.data;
                console.log(vm.infochargtype);
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getTotalchargement_type = function () {
        WS.getData('chargements/getTotalchargement_type', { tournee_code: vm.chargement.ch.tournee_code, code_jour: vm.chargement.ch.code_jour })
            .then(function (response) {
                if (response.data.length > 0) {
                    _.each(vm.produits, (element, index) => {
                        _.each(response.data, (prod, indprod) => {
                            if (element.code == prod.produit_code) {
                                vm.produits[index].total_chargement = prod.sum_quantite;
                                vm.produits[index].qte_gajou = prod.qte_gajou;
                                vm.produits[index].retour_conforme = prod.retour_conforme;

                            }
                        });
                    });
                } else {
                    _.each(vm.produits, (element, index) => {
                        vm.produits[index].total_chargement = undefined;
                        vm.produits[index].qte_gajou = undefined;
                        vm.produits[index].retour_conforme = undefined;
                    });
                }

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.export_chargement_type = function (tableId) {

        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = 'ChargementType_||_' + vm.chargement.ch.code_jour + '_||_' + vm.chargement.ch.tournee_code;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.total_Casier_chargement_type = function () {

        vm.total_casier = _.reduce(vm.chargement['ch']['produits'], function (memo, num) {
            //console.log(memo, num)
            if (num.quantite != undefined && num.quantite != NaN) {
                return memo + Math.round(Number(num.quantite) / num.colisage);
            } else {
                return memo;
            }
        }, 0);
    };

    vm.selectProdCaisse = function () {
        WS.get('produits/selectProdCaisse')
            .then(function (response) {
                vm.prods_caisses = response.data;
                vm.prods_caisses.push({
                    code: 'caisse_null',
                    libelle: 'Autre'
                })
                vm.prods_caisses_pluck = _.pluck(vm.prods_caisses, 'code');
                vm.all_boxes = [];
                _.each(vm.prods_caisses, (element, index) => {
                    vm['total_casier_' + element.code.toLowerCase()] = 0;
                    vm['total_casier_' + element.code.toLowerCase() + '_chiffre'] = 0;
                    vm.all_boxes.push('total_casier_' + element.code.toLowerCase())
                    vm.all_boxes.push('total_casier_' + element.code.toLowerCase() + '_chiffre')
                })

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.total_of_boxes = () => {
        vm.all_boxes_casier = 0
        vm.all_boxes_casier_chiffre = 0
        _.each(vm.prods_caisses, (element, index) => {
            vm.all_boxes_casier += vm['total_casier_' + element.code.toLowerCase()] ? vm['total_casier_' + element.code.toLowerCase()] : 0
            vm.all_boxes_casier_chiffre += vm['total_casier_' + element.code.toLowerCase() + '_chiffre'] ? vm['total_casier_' + element.code.toLowerCase() + '_chiffre'] : 0
        })

    }

    vm.calculateTotalCaisse = function (caisse_name = 'caisse_null', id = null, produit_code = null) {
        caisse_name = caisse_name ? caisse_name.toLowerCase() : 'caisse_null';
        //if (!_.contains(vm.prods_caisses_pluck, vm.chargement['ch'][id]['code'])) {


        //console.log(id, produit_code)
        vm['total_casier_' + caisse_name] = _.reduce(vm.chargement['ch'], function (value, prod) {
            value = value ? value : 0;
            if (typeof prod === 'object') {
                //if (prod.hors_box == 0) {
                if (
                    vm.chargement['ch'][prod.produit_id]
                    && !_.contains(vm.prods_caisses_pluck, vm.chargement['ch'][prod.produit_id]['produit_code'])
                ) {
                    //recheck caisse_code 
                    vm.chargement['ch'][prod.produit_id]['caisse_code'] =
                        vm.chargement['ch'][prod.produit_id]['caisse_code'] ?
                            vm.chargement['ch'][prod.produit_id]['caisse_code'] :
                            'caisse_null';

                    vm.chargement['ch'][prod.produit_id]['quantite'] =
                        vm.chargement['ch'][prod.produit_id]['quantite'] ?
                            vm.chargement['ch'][prod.produit_id]['quantite'] : 0;

                    vm.chargement['ch'][prod.produit_id]['demande_chargement_p'] =
                        vm.chargement['ch'][prod.produit_id]['demande_chargement_p'] ?
                            vm.chargement['ch'][prod.produit_id]['demande_chargement_p'] : 0;

                    if (
                        vm.chargement['ch'][prod.produit_id]['caisse_code'].toLowerCase() == caisse_name
                        && !_.contains(vm.prods_caisses_pluck, vm.chargement['ch'][prod.produit_id]['code'])
                    ) {

                        //console.log(prod, vm.chargement['ch'][prod.produit_id]['produit_code'], vm.chargement['ch'][prod.produit_id]['quantite'])
                        var temp_value = Number(vm.chargement['ch'][prod.produit_id]['quantite']);
                        // console.log('calculate demande', vm.chargement.ch.demande_chargement, temp_value)
                        temp_value += (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][prod.produit_id]['demande_chargement_p']) : 0;
                        // console.log('calculate demande =>+', vm.chargement.ch.demande_chargement, temp_value, vm.chargement['ch'][prod.produit_id]['demande_chargement_p'])

                        var box_quantite = (vm.chargement.ch.colisage) ? Number(vm.chargement['ch'][prod.produit_id]['box_quantite']) : 0;
                        return value + Math.round(temp_value / prod.colisage) + box_quantite

                    } else {
                        return value;
                    }
                } else {
                    return value;
                }
                // } else {
                //     return value;
                // }
            } else {
                return value;
            }
        }, 0);


        if (id) {
            if (!_.contains(vm.prods_caisses_pluck, vm.chargement['ch'][id]['produit_code'])) {
                /**
                 * get caisse
                 */
                var caisse = _.find(vm.produits, function (item) { //\\
                    return (item.code.toLowerCase() == caisse_name.toLowerCase()); //  \\
                });

                // vm['total_casier_' + caisse_name]
                if (caisse) {
                    if (vm.chargement.ch[caisse.id]) {
                        vm.chargement.ch[caisse.id].quantite = vm['total_casier_' + caisse_name];
                    }
                }

                if (vm.chargement.ch.retour_hier) {
                    //console.log('check caisse id : ', caisse )
                    vm['total_casier_' + caisse_name] += (caisse) ? Number(vm.chargement.ch[caisse.id]['retour_hier_p']) : 0;
                }

                if (vm.chargement.ch.retourI_hier) {
                    //console.log('check caisse id : ', caisse )
                    vm['total_casier_' + caisse_name] += (caisse) ? Number(vm.chargement.ch[caisse.id]['retourI_hier_p']) : 0;
                }
                if (vm.chargement.ch.demande_chargement) {
                    //console.log('check caisse id : ', caisse )
                    vm['total_casier_' + caisse_name] += (caisse) ? Number(vm.chargement.ch[caisse.id]['demande_chargement_p']) : 0;
                }


            } else {
                /**
                * get caisse
                */
                var caisse = _.find(vm.produits, function (item) { //\\
                    return (item.code.toLowerCase() == caisse_name.toLowerCase()); //  \\
                });

                if (vm.chargement['ch'][id].produit_code.toLowerCase() == caisse_name.toLowerCase()) {
                    vm['total_casier_' + caisse_name] = vm.chargement['ch'][id].quantite
                }
                if (vm.chargement.ch.retour_hier) {
                    //console.log('check caisse id : ', caisse )
                    vm['total_casier_' + caisse_name] += (caisse) ? Number(vm.chargement.ch[caisse.id]['retour_hier_p']) : 0;
                }
                if (vm.chargement.ch.retourI_hier) {
                    //console.log('check caisse id : ', caisse )
                    vm['total_casier_' + caisse_name] += (caisse) ? Number(vm.chargement.ch[caisse.id]['retourI_hier_p']) : 0;
                }
                if (vm.chargement.ch.demande_chargement) {
                    //console.log('check caisse id : ', caisse )
                    vm['total_casier_' + caisse_name] += (caisse) ? Number(vm.chargement.ch[caisse.id]['demande_chargement_p']) : 0;
                }
            }
        } else {
            /**
            * get caisse
            */
            var caisse = _.find(vm.produits, function (item) { //\\
                return (item.code.toLowerCase() == caisse_name.toLowerCase()); //  \\
            });

            // vm['total_casier_' + caisse_name]
            if (caisse) {
                if (vm.chargement.ch[caisse.id]) {
                    vm.chargement.ch[caisse.id].quantite = vm['total_casier_' + caisse_name];
                }
            }

            if (vm.chargement.ch.retour_hier) {
                //console.log('check caisse id : ', caisse )
                vm['total_casier_' + caisse_name] += (caisse) ? Number(vm.chargement.ch[caisse.id]['retour_hier_p']) : 0;
            }
            if (vm.chargement.ch.retourI_hier) {
                //console.log('check caisse id : ', caisse )
                vm['total_casier_' + caisse_name] += (caisse) ? Number(vm.chargement.ch[caisse.id]['retourI_hier_p']) : 0;
            }
            if (vm.chargement.ch.demande_chargement) {
                //console.log('check caisse id : ', caisse )
                vm['total_casier_' + caisse_name] += (caisse) ? Number(vm.chargement.ch[caisse.id]['demande_chargement_p']) : 0;
            }
        }



        //console.log(caisse, '=====>', caisse_name, 'vm.total_casier_' + caisse_name, ' = ', vm['total_casier_' + caisse_name])
    }

    vm.calculateTotalCaisseValeur = function (caisse_name = 'caisse_null', id = null, produit_code = null) {
        caisse_name = caisse_name ? caisse_name.toLowerCase() : 'caisse_null';
        vm['total_casier_' + caisse_name + '_chiffre'] = _.reduce(vm.chargement['ch'], function (value, prod) {
            value = value ? value : 0;
            if (typeof prod === 'object') {
                //if (prod.hors_box == 0) {
                if (
                    vm.chargement['ch'][prod.produit_id]
                    && !_.contains(vm.prods_caisses_pluck, vm.chargement['ch'][prod.produit_id]['produit_code'])
                ) {
                    //recheck caisse_code 
                    vm.chargement['ch'][prod.produit_id]['caisse_code'] =
                        vm.chargement['ch'][prod.produit_id]['caisse_code'] ?
                            vm.chargement['ch'][prod.produit_id]['caisse_code'] :
                            'caisse_null';

                    vm.chargement['ch'][prod.produit_id]['quantite'] =
                        vm.chargement['ch'][prod.produit_id]['quantite'] ?
                            vm.chargement['ch'][prod.produit_id]['quantite'] : 0;

                    vm.chargement['ch'][prod.produit_id]['demande_chargement_p'] =
                        vm.chargement['ch'][prod.produit_id]['demande_chargement_p'] ?
                            vm.chargement['ch'][prod.produit_id]['demande_chargement_p'] : 0;

                    if (
                        vm.chargement['ch'][prod.produit_id]['caisse_code'].toLowerCase() == caisse_name
                        && !_.contains(vm.prods_caisses_pluck, vm.chargement['ch'][prod.produit_id]['code'])
                    ) {

                        //console.log(prod, vm.chargement['ch'][prod.produit_id]['produit_code'], vm.chargement['ch'][prod.produit_id]['quantite'])
                        var temp_value = Number(vm.chargement['ch'][prod.produit_id]['quantite']);
                        // console.log('calculate demande', vm.chargement.ch.demande_chargement, temp_value)
                        temp_value += (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][prod.produit_id]['demande_chargement_p']) : 0;
                        // console.log('calculate demande =>+', vm.chargement.ch.demande_chargement, temp_value, vm.chargement['ch'][prod.produit_id]['demande_chargement_p'])

                        // retour Hier
                        temp_value += (vm.chargement.ch.retour_hier) ? Number(vm.chargement['ch'][prod.produit_id]['retour_hier_p']) : 0;


                        //retour I hier
                        temp_value += (vm.chargement.ch.retourI_hier) ? Number(vm.chargement['ch'][prod.produit_id]['retourI_hier_p']) : 0;


                        var box_quantite = (vm.chargement.ch.colisage) ? Number(vm.chargement['ch'][prod.produit_id]['box_quantite']) : 0;
                        return value + ((Math.round(temp_value) + Math.round(box_quantite * prod.colisage)) * prod.prix_ttc)


                    } else {
                        return value;
                    }
                } else {
                    return value;
                }
                // } else {
                //     return value;
                // }
            } else {
                return value;
            }
        }, 0);



    }



    vm.total_Casier_chargement = function (produit_code = null, produit_id = null, caisse_code = null) {

        _.each(vm.prods_caisses, (caisse) => {
            vm.calculateTotalCaisse(caisse.code, produit_id, produit_code)
            vm.calculateTotalCaisseValeur(caisse.code, produit_id, produit_code)
        })
        vm.total_of_boxes()
        /**
         * total caisse
         */

        // if (caisse_code) {
        //     vm.total_Casier_chargement_caisse(produit_code, produit_id, caisse_code);
        // } else {
        //     //console.log('test 1 :', produit_code, produit_id, caisse_code);
        //     var produit_code = produit_code ? produit_code.toLowerCase() : produit_code;
        //     if (produit_code != 'caisse' && produit_code != 'palette' && produit_code != 'ga' && produit_code != 'caisse003') {

        //         vm.total_casier_charg = 0;

        //         if (caisse_code) {
        //             var caisse = _.find(vm.produits, function (item) { //\\
        //                 return (item.code == caisse_code); //  \\
        //             }); //    \\
        //         } else {
        //             var caisse = _.find(vm.produits, function (item) { //\\
        //                 return (item.code.toLowerCase() == 'caisse' || item.code.toLowerCase() == 'ga'); //  \\
        //             }); //    \\
        //         }

        //         if (caisse) {
        //             vm.chargement.ch[caisse.id].quantite = 0;
        //         }

        //         //console.log('test 2 :', caisse);
        //         vm.total_casier_charg = _.reduce(vm.chargement['ch'], function (value, prod) {
        //             value = value ? value : 0;
        //             if (typeof prod === 'object') {
        //                 if (prod.hors_box == 0) {
        //                     if (vm.chargement['ch'][prod.produit_id]) {
        //                         if (vm.chargement['ch'][prod.produit_id]['quantite'] != undefined && vm.chargement['ch'][prod.produit_id]['quantite'] != NaN) {

        //                             var temp_value = Number(vm.chargement['ch'][prod.produit_id]['quantite']);

        //                             //temp_value += (vm.chargement.ch.retour_hier) ? Number(vm.chargement['ch'][prod.produit_id]['retour_hier_p']) : 0;
        //                             //console.log(prod.produit_code, temp_value);
        //                             temp_value += (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][prod.produit_id]['demande_chargement_p']) : 0;
        //                             //console.log(prod.produit_code, '==========>', value, temp_value);
        //                             //add box 
        //                             var box_quantite = (vm.chargement.ch.colisage) ? Number(vm.chargement['ch'][prod.produit_id]['box_quantite']) : 0;
        //                             return value + Math.round(temp_value / prod.colisage) + box_quantite
        //                             // if (vm.chargement.ch.colisage == false) {
        //                             //     return value + Math.round(temp_value / prod.colisage) + box_quantite;
        //                             // } else {
        //                             //     return value + temp_value + box_quantite;
        //                             // }
        //                         } else {
        //                             return value;
        //                         }
        //                     }
        //                 } else {
        //                     return value;
        //                 }
        //             } else {
        //                 return value;
        //             }
        //         }, 0);
        //         //vm.total_casier_charg += (vm.chargement.ch.retour_hier) ? Number(vm.chargement.ch[caisse.id]['retour_hier_p']) : vm.total_casier_charg;
        //         if (vm.chargement.ch.retour_hier) {
        //             //console.log('check caisse id : ', caisse )
        //             vm.total_casier_charg += (caisse) ? Number(vm.chargement.ch[caisse.id]['retour_hier_p']) : 0;
        //         }
        //         /**
        //          * total_charg_only
        //          */
        //         vm.total_casier_charg_only = _.reduce(vm.chargement['ch'], function (value, prod) {
        //             if (typeof prod === 'object') {
        //                 if (prod.hors_box == 0) {
        //                     if (vm.chargement['ch'][prod.produit_id]) {
        //                         if (vm.chargement['ch'][prod.produit_id]['quantite'] != undefined && vm.chargement['ch'][prod.produit_id]['quantite'] != NaN) {

        //                             var temp_value = Number(vm.chargement['ch'][prod.produit_id]['quantite']);
        //                             //add box 
        //                             var box_quantite = (vm.chargement.ch.colisage) ? Number(vm.chargement['ch'][prod.produit_id]['box_quantite']) : 0;
        //                             return value + Math.round(temp_value / prod.colisage) + box_quantite;

        //                             // if (vm.chargement.ch.colisage == false) {
        //                             //     return value + Math.round(temp_value / prod.colisage) + box_quantite;
        //                             // } else {
        //                             //     return value + temp_value + box_quantite;
        //                             // }
        //                         } else {
        //                             return value;
        //                         }
        //                     }
        //                 } else {
        //                     return value;
        //                 }
        //             } else {
        //                 return value;
        //             }
        //         }, 0);

        //         /**
        //          * total_demande_chargement only
        //          */
        //         vm.total_casier_charg_dc = _.reduce(vm.chargement['ch'], function (value, prod) {
        //             if (typeof prod === 'object') {
        //                 if (prod.hors_box == 0) {
        //                     if (vm.chargement['ch'][prod.produit_id]) {
        //                         if (vm.chargement['ch'][prod.produit_id]['quantite'] != undefined && vm.chargement['ch'][prod.produit_id]['quantite'] != NaN) {

        //                             var temp_value = (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][prod.produit_id]['demande_chargement_p']) : 0;

        //                             return value + Math.round(temp_value / prod.colisage);
        //                             // if (vm.chargement.ch.colisage == false) {
        //                             //     return value + Math.round(temp_value / prod.colisage);
        //                             // } else {
        //                             //     return value + temp_value;
        //                             // }
        //                         } else {
        //                             return value;
        //                         }
        //                     }
        //                 } else {
        //                     return value;
        //                 }
        //             } else {
        //                 return value;
        //             }
        //         }, 0);

        //         /////////////// append vm.total_casier_charg to input caisse //////////////////\                            
        //         //      \\
        //         if (caisse) { //        \\
        //             // if(vm.chargement.ch.retour_hier){
        //             //     vm.chargement.ch[caisse.id].quantite = 0;
        //             // }else{
        //             vm.chargement.ch[caisse.id].quantite = vm.total_casier_charg_only;
        //             //}
        //             //          \\
        //         } //   DEVAPP   \\
        //         ////////////////////////////////////////////////////////////////////////==============\\

        //         /**
        //          * montant total
        //          */
        //         vm.total_montant_charg = 0;
        //         vm.total_montant_charg = _.reduce(vm.chargement['ch'], function (value, prod) {
        //             if (typeof prod === 'object') {
        //                 // console.log(value, prod);


        //                 if (prod.quantite != undefined && prod.quantite != NaN) {
        //                     var temp_value = Number(vm.chargement['ch'][prod.produit_id]['quantite']);
        //                     temp_value += (vm.chargement.ch.retour_hier) ? Number(vm.chargement['ch'][prod.produit_id]['retour_hier_p']) : 0;
        //                     temp_value += (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][prod.produit_id]['demande_chargement_p']) : 0;

        //                     //add box 
        //                     var box_quantite = (vm.chargement.ch.colisage) ? Number(vm.chargement['ch'][prod.produit_id]['box_quantite']) : 0;


        //                     if (vm.chargement.ch.colisage) {
        //                         temp_value = (temp_value) + (box_quantite * prod.colisage)
        //                     }
        //                     return value + (temp_value * prod.prix_ttc);
        //                 } else {
        //                     return value;
        //                 }

        //             } else {
        //                 return value;
        //             }
        //         }, 0);
        //     } else {
        //         vm.total_casier_charg = 0
        //         vm.total_casier_charg += (vm.chargement.ch[produit_id].quantite) ? Number(vm.chargement.ch[produit_id].quantite) : 0;
        //         vm.total_casier_charg += (vm.chargement.ch.colisage) ? Number(vm.chargement.ch[produit_id].box_quantite) : 0;
        //         vm.total_casier_charg += (vm.chargement.ch.retour_hier) ? Number(vm.chargement.ch[produit_id]['retour_hier_p']) : 0;
        //         vm.total_casier_charg += (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][produit_id]['demande_chargement_p']) : 0;
        //     }
        //     //console.log(produit_code, vm.total_casier_charg)
        // }


    };



    vm.total_Casier_chargement_caisse = function (produit_code = null, produit_id = null, caisse_code = null) {
        /**
         * total caisse
         */
        console.log('test 1 :', produit_code, produit_id, caisse_code);
        var produit_code = produit_code ? produit_code.toLowerCase() : produit_code;
        if (produit_code != 'caisse' && produit_code != 'palette' && produit_code != 'ga' && produit_code != 'caisse003') {

            vm.total_casier_charg_caisse = 0;

            if (caisse_code) {
                var caisse = _.find(vm.produits, function (item) { //\\
                    return (item.code == caisse_code); //  \\
                }); //    \\
            } else {
                var caisse = _.find(vm.produits, function (item) { //\\
                    return (item.code.toLowerCase() == 'caisse' || item.code.toLowerCase() == 'ga'); //  \\
                }); //    \\
            }

            if (caisse) {
                vm.chargement.ch[caisse.id].quantite = 0;
            }

            console.log('test 2 :', caisse);
            vm.total_casier_charg_caisse = _.reduce(vm.chargement['ch'], function (value, prod) {
                value = value ? value : 0;
                if (typeof prod === 'object') {
                    if (prod.hors_box == 0 || caisse_code) {
                        if (vm.chargement['ch'][prod.produit_id]) {
                            if (vm.chargement['ch'][prod.produit_id]['quantite'] != undefined && vm.chargement['ch'][prod.produit_id]['quantite'] != NaN) {

                                var temp_value = Number(vm.chargement['ch'][prod.produit_id]['quantite']);
                                //temp_value += (vm.chargement.ch.retour_hier) ? Number(vm.chargement['ch'][prod.produit_id]['retour_hier_p']) : 0;
                                //console.log(prod.produit_code, temp_value);
                                temp_value += (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][prod.produit_id]['demande_chargement_p']) : 0;

                                var box_quantite = (vm.chargement.ch.colisage) ? Number(vm.chargement['ch'][prod.produit_id]['box_quantite']) : 0;

                                //console.log(prod.produit_code, '==========>', value, temp_value);
                                return value + Math.round(temp_value / prod.colisage) + box_quantite;

                                // if (vm.chargement.ch.colisage == false) {
                                //     return value + Math.round(temp_value / prod.colisage);
                                // } else {
                                //     return value + temp_value;
                                // }
                            } else {
                                return value;
                            }
                        }
                    } else {
                        return value;
                    }
                } else {
                    return value;
                }
            }, 0);
            //vm.total_casier_charg += (vm.chargement.ch.retour_hier) ? Number(vm.chargement.ch[caisse.id]['retour_hier_p']) : vm.total_casier_charg;
            //vm.total_casier_charg_caisse += (vm.chargement.ch.retour_hier) ? Number(vm.chargement.ch[caisse.id]['retour_hier_p']) : 0;
            if (vm.chargement.ch.retour_hier) {
                vm.total_casier_charg_caisse += (caisse.id) ? Number(vm.chargement.ch[caisse.id]['retour_hier_p']) : 0;
            }
            console.log('vm.total_casier_charg_caisse', vm.total_casier_charg_caisse)

            /**
             * total_charg_only
             */
            vm.total_casier_charg_only_caisse = _.reduce(vm.chargement['ch'], function (value, prod) {
                value = value ? value : 0;

                if (typeof prod === 'object') {
                    if (caisse_code) {
                        if (vm.chargement['ch'][prod.produit_id]) {
                            if (vm.chargement['ch'][prod.produit_id]['quantite'] != undefined && vm.chargement['ch'][prod.produit_id]['quantite'] != NaN) {

                                var temp_value = Number(vm.chargement['ch'][prod.produit_id]['quantite']);

                                var box_quantite = (vm.chargement.ch.colisage) ? Number(vm.chargement['ch'][prod.produit_id]['box_quantite']) : 0;

                                return value + Math.round(temp_value / prod.colisage) + box_quantite;


                                // if (vm.chargement.ch.colisage == false) {
                                //     return value + Math.round(temp_value / prod.colisage);
                                // } else {
                                //     return value + temp_value;
                                // }
                            } else {
                                return value;
                            }
                        }
                    } else {
                        return value;
                    }
                } else {
                    return value;
                }
            }, 0);

            // console.log('vm.total_casier_charg_only_caisse', vm.total_casier_charg_only_caisse)

            /**
             * total_demande_chargement only
             */
            vm.total_casier_charg_dc_caisse = _.reduce(vm.chargement['ch'], function (value, prod) {
                if (typeof prod === 'object') {
                    if (prod.hors_box == 0 || caisse_code) {
                        if (vm.chargement['ch'][prod.produit_id]) {
                            if (vm.chargement['ch'][prod.produit_id]['quantite'] != undefined && vm.chargement['ch'][prod.produit_id]['quantite'] != NaN) {

                                var temp_value = (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][prod.produit_id]['demande_chargement_p']) : 0;

                                if (vm.chargement.ch.colisage == false) {
                                    return value + Math.round(temp_value / prod.colisage);
                                } else {
                                    return value + temp_value;
                                }
                            } else {
                                return value;
                            }
                        }
                    } else {
                        return value;
                    }
                } else {
                    return value;
                }
            }, 0);

            /////////////// append vm.total_casier_charg to input caisse //////////////////\                            
            //      \\
            if (caisse) { //        \\
                // if(vm.chargement.ch.retour_hier){
                //     vm.chargement.ch[caisse.id].quantite = 0;
                // }else{
                vm.chargement.ch[caisse.id].quantite = vm.total_casier_charg_only_caisse;
                //}
                //  console.log('-------caisse------', caisse, vm.chargement.ch[caisse.id].quantite)
                //          \\
            } //   DEVAPP   \\
            ////////////////////////////////////////////////////////////////////////==============\\

            /**
             * montant total
             */
            vm.total_montant_charg = 0;
            vm.total_montant_charg = _.reduce(vm.chargement['ch'], function (value, prod) {
                if (typeof prod === 'object') {
                    // console.log(value, prod);


                    if (prod.quantite != undefined && prod.quantite != NaN) {
                        var temp_value = Number(vm.chargement['ch'][prod.produit_id]['quantite']);
                        temp_value += (vm.chargement.ch.retour_hier) ? Number(vm.chargement['ch'][prod.produit_id]['retour_hier_p']) : 0;
                        temp_value += (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][prod.produit_id]['demande_chargement_p']) : 0;

                        //add box 
                        var box_quantite = (vm.chargement.ch.colisage) ? Number(vm.chargement['ch'][prod.produit_id]['box_quantite']) : 0;


                        if (vm.chargement.ch.colisage) {
                            temp_value = (temp_value) + (box_quantite * prod.colisage)
                        }

                        return value + (temp_value * prod.prix_ttc);
                    } else {
                        return value;
                    }

                } else {
                    return value;
                }
            }, 0);
        } else {
            vm.total_casier_charg_caisse = 0
            vm.total_casier_charg_caisse += (vm.chargement.ch[produit_id].quantite) ? Number(vm.chargement.ch[produit_id].quantite) : 0;
            vm.total_casier_charg_caisse += (vm.chargement.ch.retour_hier) ? Number(vm.chargement.ch[produit_id]['retour_hier_p']) : 0;
            vm.total_casier_charg_caisse += (vm.chargement.ch.demande_chargement) ? Number(vm.chargement['ch'][produit_id]['demande_chargement_p']) : 0;
        }
        //console.log(produit_code, vm.total_casier_charg_caisse)

    };


    vm.reinisialiserQTE = function () {
        _.each(vm.produits, (el, ind) => {
            _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite: 0 });
        });
        vm.total_Casier_chargement();
    }

    vm.reinisialiserDLC = function () {
        _.each(vm.produits, (el, ind) => {
            _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite: 0 });
        });
    }

    vm.reinisialiserDLC = function () {
        _.each(vm.produits, (el, ind) => {
            _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite_dlc: 0 });
        });
    }

    vm.reinisialiserCASSE = function () {
        _.each(vm.produits, (el, ind) => {
            _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite_casse: 0 });
        });
    }


    vm.stat_table_vente = function () {
        var data = {
            user_code: vm.stat_user_code,
            date_debut: vm.stat_date_debut,
            date_fin: vm.stat_date_fin
        }
        WS.getData('chargements/stat_table_vente', data)
            .then(function (response) {
                vm.stat_vente = response.data;

                vm.sum_qte_total = 0;
                vm.sum_ca_total = 0;
                vm.sum_qte_total_cl = 0;
                vm.sum_ca_total_cl = 0;
                // vm.sum_qte_total_out_cl = 0;
                // vm.sum_ca_total_out_cl = 0;

                _.each(vm.stat_vente, (element, index) => {
                    if (element.hors_box == 0) {
                        vm.sum_qte_total += element.qte_total;
                        vm.sum_ca_total += element.ca_total;

                        vm.sum_qte_total_cl += element.qte_total_cl;
                        vm.sum_ca_total_cl += element.ca_total_cl;
                    }
                    // vm.sum_qte_total_out_cl += element.qte_total_out_cl;
                    // vm.sum_ca_total_out_cl += element.ca_total_out_cl;
                });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.stat_table_vente_commercials = function () {
        var data = {
            famille_code: vm.stat_famille_code,
            date_debut: vm.stat_date_debut,
            date_fin: vm.stat_date_fin
        }
        WS.getData('chargements/stat_table_vente_commercials', data)
            .then(function (response) {
                vm.stat_vente = response.data;

                vm.sum_qte_lait_cl = 0;
                vm.sum_ca_qte_lait_cl = 0;
                vm.sum_qte_lait = 0;
                vm.sum_ca_qte_lait = 0;

                vm.sum_qte_pots_cl = 0;
                vm.sum_ca_qte_pots_cl = 0;
                vm.sum_qte_pots = 0;
                vm.sum_ca_qte_pots = 0;



                _.each(vm.stat_vente, (element, index) => {
                    //if (element.hors_box == 0) {
                    vm.sum_qte_lait_cl += element.qte_lait_cl;
                    vm.sum_ca_qte_lait_cl += element.ca_sum_vente_lait_cl;
                    vm.sum_qte_lait += element.qte_lait;
                    vm.sum_ca_qte_lait += element.ca_sum_vente_lait;

                    vm.sum_qte_pots_cl += element.qte_pots_cl;
                    vm.sum_ca_qte_pots_cl += element.ca_sum_vente_pots_cl;
                    vm.sum_qte_pots += element.qte_pots;
                    vm.sum_ca_qte_pots += element.ca_sum_vente_pots;
                    //}

                });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.exportEnteteLigneStatVente = function (type, type_client) {
        var url = `chargements/exportEnteteLigneVenteReel/${type}/${type_client}?date_debut=${vm.stat_date_debut}&date_fin=${vm.stat_date_fin}&user_code=${vm.stat_user_code}`;
        $window.location.href = AppSettings.apiUrl + url;
    };

    vm.exportVenteReelCOM = function (val1, val2, title) {
        var famille_code = (vm.stat_famille_code) ? vm.stat_famille_code : '';
        var filename = title + famille_code + '__' + vm.stat_date_debut + '__' + vm.stat_date_fin;

        var file = {
            sheetid: filename,
            headers: true,
            // caption: {
            //     title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            //     style: 'font-size: 50px; color:blue;'
            // },
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [

                { columnid: 'code', title: 'Code' },
                { columnid: val1, title: 'Quantite Lait' },
                { columnid: val2, title: 'Quantite Pots' }

            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        if (val1 == 'qte_cl_passager_lait' && val2 == 'qte_cl_passager_pots') {
            alasql('SELECT code as code,(qte_lait - qte_lait_cl) as qte_cl_passager_lait,(qte_pots - qte_pots_cl) as qte_cl_passager_pots   INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.stat_vente]);
        } else {
            alasql('SELECT *  INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.stat_vente]);
        }
    };


    vm.exportVenteReel = function () {
        var user_code = (vm.stat_user_code) ? vm.stat_user_code : '';
        var filename = 'vente-reel__' + user_code + '__' + vm.stat_date_debut + '__' + vm.stat_date_fin;

        var file = {
            sheetid: filename,
            headers: true,
            // caption: {
            //     title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            //     style: 'font-size: 50px; color:blue;'
            // },
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [

                { columnid: 'code', title: 'Code' },
                { columnid: 'qte_total', title: 'Quantite' },
                { columnid: 'ca_total', title: 'Ca' }

            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        alasql('SELECT *  INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.stat_vente]);
    };

    vm.venteFactureCL = function () {
        var user_code = (vm.stat_user_code) ? vm.stat_user_code : '';
        var filename = 'vente-facturé-cl__' + user_code + '__' + vm.stat_date_debut + '__' + vm.stat_date_fin;

        var file = {
            sheetid: filename,
            headers: true,
            // caption: {
            //     title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            //     style: 'font-size: 50px; color:blue;'
            // },
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [

                { columnid: 'code', title: 'Code' },
                { columnid: 'qte_total_cl', title: 'Quantite_cl' },
                { columnid: 'ca_total_cl', title: 'Ca_cl' }

            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        alasql('SELECT *  INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.stat_vente]);
    };

    vm.venteReelCPassager = function () {
        var user_code = (vm.stat_user_code) ? vm.stat_user_code : '';
        var filename = 'vente-reel-client-passager__' + user_code + '__' + vm.stat_date_debut + '__' + vm.stat_date_fin;

        var file = {
            sheetid: filename,
            headers: true,
            // caption: {
            //     title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            //     style: 'font-size: 50px; color:blue;'
            // },
            column: {
                style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [

                { columnid: 'code', title: 'Code' },
                { columnid: 'qte', title: 'Quantite' },
                { columnid: 'ca', title: 'Ca' }

            ],
            row: {
                style: function (sheet, row, rowidx) {
                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                }
            }
        };
        alasql('SELECT code as code,(qte_total - qte_total_cl) as qte,(ca_total - ca_total_cl) as ca   INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.stat_vente]);
    };

    vm.getSommeByAttributeValuebl = function (liste, attribute) {
        if (!liste) return;
        var total = 0;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] != 'null' && liste[i][attribute] != null) {
                total = parseFloat(total) + parseFloat(liste[i][attribute]);
            }


        }
        return total;
    }



    // Vente Facturé CL	Vente reel Client Passager

    vm.log_test = (verif_import) => {
        console.log(verif_import);
    };

    vm.checkVirtualClient = () => {
        WS.getData('clients/checkVirtualClient', { user_code: vm.stat_commercial }).then(
            (response) => {
                if (response.data.success) {
                    //check plafond client and depot
                    //console.log(response.data.client.code, vm.userinfo.depot_code);
                    vm.virtual_client_code = response.data.client.code;
                    vm.checkPlafondToGenerateBl(response.data.client.code, vm.userinfo.depot_code);
                } else {
                    vm.modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'confirm_create_client',
                        size: 'md',
                        backdrop: true,
                        windowClass: 'z-index-sec-modal',
                        controller: function ($scope, $translate) {
                            $scope.message = $translate.instant("Création d'un client virtuel pour cette utilisateur : Virtual-") + vm.stat_commercial;
                            $scope.plafond = vm.plafond;

                            $scope.createVirtualClient = function () {
                                if ($scope.plafond_credit != '' && $scope.plafond_credit != undefined) {
                                    var data = {
                                        nom: vm.userinfo.nom,
                                        prenom: vm.userinfo.prenom,
                                        routing_code: vm.userinfo.routing_code,
                                        depot_code: vm.userinfo.depot_code,
                                        user_code: vm.stat_commercial,

                                        adresse_facturation: vm.userinfo.adresse,
                                        telephone: vm.userinfo.telephone,
                                        mobile: vm.userinfo.mobile,
                                        zone: vm.userinfo.zone_code,
                                        secteur: vm.userinfo.secteur_code,
                                        region: vm.userinfo.region_code,
                                        longitude: vm.userinfo.longitude,
                                        latitude: vm.userinfo.latitude,
                                        email: vm.userinfo.email,
                                        code_a_barre: vm.userinfo.code_a_barre,
                                        matricule_fiscale: vm.userinfo.matricule,


                                        plafond_credit: $scope.plafond_credit,
                                        plafond_credit_cheque: $scope.plafond_cheque,
                                        plafond_credit_traite: $scope.plafond_traite,
                                    };
                                    //console.log(data);
                                    WS.post('clients/createVirtualClient', data).then(
                                        function (response) {
                                            if (response.data.success) {
                                                vm.virtual_client_code = response.data.client.code;
                                                swal(
                                                    $translate.instant('Succès'),
                                                    $translate.instant('Client Virtuel ajouté avec succé, Clicker sur ok pour verifier le crédit'),
                                                    'success'
                                                ).then(function () {
                                                    vm.modalInstance.close();
                                                    vm.checkPlafondToGenerateBl(response.data.client.code, vm.userinfo.depot_code);
                                                }).catch(function () {
                                                    vm.modalInstance.close();
                                                    vm.checkPlafondToGenerateBl(response.data.client.code, vm.userinfo.depot_code);
                                                });
                                            } else {
                                                swal(
                                                    $translate.instant('Attention'),
                                                    $translate.instant(response.data.message),
                                                    'warning'
                                                );
                                            }

                                        },
                                        function () {
                                            swal(
                                                $translate.instant('Attention'),
                                                $translate.instant('erreur de chargement des données!!'),
                                                'warning'
                                            );
                                        }
                                    );
                                } else {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant('plafond credit Obligatoire'),
                                        'warning'
                                    );
                                }
                            }
                        }
                    });
                }
            },
            (error) => {

            }
        )
    };

    vm.generate_bl_working = false;


    vm.generateBl = () => {
        vm.generate_bl_working = true;
        vm.modalInstancePlafond.close();
        // console.log(vm.chargements_stats);
        /**
         * remove comma from recette
         */
        var recette = vm.caisse.recette;
        recette = Number(recette);
        recette = recette.toString().replace(/,/g, '');
        //console.log(recette);
        /**
         * remove comma from recette
         */
        var journee = Number(vm.caisse_espece) + Number(vm.caisse_cheque) + Number(vm.caisse_credit) +
            Number(vm.creditgms) +
            Number(vm.credit_collectivite) +
            Number(vm.credit_collectivite_depot) +
            Number(vm.caisse_depense) +
            Number(vm.caisse_carburant) +
            Number(vm.caisse_entretien) +
            Number(vm.caisse_gratuite) +
            Number(vm.caisse_versement_banque); +
                Number(vm.caisse_ticket); +
                    Number(vm.caisse_avoir); +
                        Number(vm.caisse_dlc);
        var ecart = Number(recette) + Number(vm.total_sum_dlc_uniq_chiffre) -
            Number(vm.caisse_espece) -
            Number(vm.caisse_cheque) -
            Number(vm.caisse_credit) -
            Number(vm.creditgms) -
            Number(vm.credit_collectivite) -
            Number(vm.credit_collectivite_depot) -
            Number(vm.caisse_depense) -
            Number(vm.caisse_carburant) -
            Number(vm.caisse_entretien) -
            Number(vm.caisse_gratuite) -
            Number(vm.caisse_versement_banque) -
            Number(vm.caisse_remise) +
            Number(vm.caisse_recouvrement) -
            Number(vm.caisse_ticket) -
            Number(vm.caisse_avoir) -
            Number(vm.caisse_dlc);
        ecart = ecart.toFixed(3);
        //   console.log("ecart "+ecart);

        var data = {
            date_debut: vm.stat_date_debut,
            commercial: vm.stat_commercial,
            client_code: vm.virtual_client_code,
            depot_code: vm.userinfo.depot_code,

            montant_vente_total: vm.total_reel,
            net_a_payer: vm.total_reel + vm.total_rec_espece + vm.total_rec_cheque - vm.total_remises - vm.total_credit - vm.total_tarifs,
            total_reel_ht: vm.total_reel_ht + vm.total_rec_espece + vm.total_rec_cheque - vm.total_remises - vm.total_credit,
            lignes: vm.chargements_stats,
            // caisse
            recette: recette,
            journee: journee,
            espece: vm.caisse_espece,
            cheque: vm.caisse_cheque,
            credit: vm.caisse_credit,
            depense: vm.caisse_depense,
            carburant: vm.caisse_carburant,
            entretien: vm.caisse_entretien,
            gratuite: vm.caisse_gratuite,
            dlc: vm.caisse_dlc,
            avoir: vm.caisse_avoir,
            ticket: vm.caisse_ticket,
            recouvrement: vm.caisse_recouvrement,
            versement_banque: vm.caisse_versement_banque,
            remise: vm.caisse_remise,
            tarifs: vm.total_tarifs,

            date: vm.caisse_date,
            ecart: ecart,
            creditgms: vm.creditgms,
            credit_collectivite: vm.credit_collectivite,
            credit_collectivite_depot: vm.credit_collectivite_depot,
            commentaire: vm.commentaire,
            //caisse: vm.stat_caisse,
            caisse_code: vm.caisse_code
        };
        // console.log(data);
        WS.post('chargements/generateBl', data)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('BL ajoutée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
                vm.generate_bl_working = false;

            })
            .then(null, function (error) {
                //console.log(error);
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('erreur d\'insertion!'),
                    'error'
                );
                vm.generate_bl_working = false;

            });
    }


    vm.checkPlafondToGenerateBl = (client_code, depot_code) => {
        vm.getCreditAndPlafondByDepot(client_code, depot_code);
    };


    vm.getCreditAndPlafondByDepot = (client_code, depot_code) => {
        WS.getData('depots/getCreditAndPlafondByDepotBilan', { depot_code, client_code, commercial_code: vm.stat_commercial }).then(
            (response) => {
                vm.plafond_depot = response.data.solde;
                vm.plafond_depot.plafond = (vm.plafond_depot.plafond) ? vm.plafond_depot.plafond : 0;
                vm.plafond_depot.solde = (vm.plafond_depot.solde) ? vm.plafond_depot.solde : 0;
                vm.generated_code_bl = response.data.generated_code_bl;
                $scope.$apply();
                vm.getCreditAndPlafondByClient(client_code);

            },
            (error) => {
                console.log(error);
            }
        );
    };


    vm.getCreditAndPlafondByClient = (client_code) => {
        WS.getData('clients/getCreditAndPlafondByClient', { client_code }).then(
            (response) => {
                vm.plafond_client = response.data;
                vm.plafond_client.plafond = (response.data.plafond) ? response.data.plafond : 0;
                vm.plafond_client.solde = (response.data.solde) ? response.data.solde : 0;
                //console.log('getCreditAndPlafondByClient',vm.plafond_client);
                $scope.$apply();

                vm.modalInstancePlafond = $uibModal.open({
                    animation: true,
                    templateUrl: 'Info_plafond',
                    size: 'md',
                    backdrop: true,
                    windowClass: 'z-index-sec-modal',
                    controller: function ($scope, $translate) {
                        $scope.caisse_credit = vm.caisse_credit;
                        $scope.plafond_client = vm.plafond_client;
                        $scope.plafond_depot = vm.plafond_depot;
                        $scope.generated_code_bl = vm.generated_code_bl;
                        $scope.generateBl = () => {
                            vm.generateBl();
                        };
                        //console.log('modal controller',$scope.caisse_credit,  $scope.plafond_client, $scope.plafond_depot );
                    }
                });
            },
            (error) => {
                console.log(error);
            }
        );
    };



    vm.getQuotaByCommercial = () => {

        if (vm.parametrages.use_quota == 1 && vm.chargement.ch.type == 'chargement') {
            WS.getData('quota/getQuotaByCommercial', {
                commercial_code: vm.chargement.ch.commercial
            }).then(
                function (response) {
                    vm.quotas = response.data;

                    _.each(vm.quotas, quota => {
                        _.each(vm.produits, prod => {
                            if (prod.code == quota.produit_code) {
                                prod.quota = quota.quantite;
                                vm.chargement['ch'][prod.id]['quota'] = quota.quantite;
                            }
                        })
                    })
                    $scope.$apply();
                },
                function (error) {
                    console.log('error : ->', error);
                }
            );
        }
    };


    vm.getParametrage = () => {
        WS.get('parametrages/getIndexForDashboard').then(
            (response) => {
                vm.parametrages = response.data;
                console.log('vm.parametrages', vm.parametrages)
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    };

    vm.selectStockByCommercial = (user_code) => {
        WS.getData('stocks/selectStockByCommercial', { user_code }).then(
            (response) => {
                vm.stock_camion = response.data.stocks;
                $scope.$apply();
            },
            (error) => {
                console.log(error);
            }
        );
    }


    vm.printCaisse = function (panel) {

        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                    <style>
                                        .noprint{display: none;}.text-center{text-align:center}
                                        th{border:1px solid #ddd!important;}
                                        td{border:1px solid #ddd!important;}
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        mywindow.document.write(document.getElementById(panel).innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }



    vm.refuseDemandeChargement = function (numero) {
        //console.log(code);
        //console.log(user);
        swal({
            title: $translate.instant(`Suppression`),
            // text: $translate.instant('code bl') + ` : `,
            html: `${$translate.instant('Vos étes sur le point de supprimer le demande de chargement')}
                    <h4 class="text-center">${numero}</h4>`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui, supprimer!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'VerificationOfPassword',
                size: 'md',
                backdrop: true,
                controller: function ($scope, $translate) {
                    $scope.message_password = $translate.instant('confirmer le mot de passe');
                    $scope.current_numero = numero;

                    $scope.refuseDemande = function () {
                        if ($scope.set_password != '' && $scope.set_password != undefined) {
                            var data = {
                                numero: $scope.current_numero,
                                password: $scope.set_password
                            };
                            console.log(data);
                            WS.post('entetecommercial/refuseDemande', data).then(
                                function (response) {
                                    if (response.data.success) {
                                        swal(
                                            $translate.instant('Succès'),
                                            $translate.instant('Supprimée'),
                                            'success'
                                        ).then(function () {
                                            $window.location.reload();
                                        }).catch(function () {
                                            $window.location.reload();
                                        });;
                                    } else {
                                        swal(
                                            $translate.instant('Attention'),
                                            $translate.instant(response.data.message),
                                            'warning'
                                        );
                                    }
                                },
                                function () {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant('erreur de chargement des données'),
                                        'warning'
                                    );
                                }
                            );
                        } else {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('Mot de passe Obligatoire'),
                                'warning'
                            );
                        }
                    }
                }
            });

        });

    };



    vm.stock_sent = {};
    vm.getStockByUser = () => {
        WS.getData('stocks/getStockByUserTransfert/' + vm.user_code_sender).then(
            function (response) {
                console.log(response.data)
                vm.stocks = response.data.stocks;
                vm.total_stocks = response.data.total_stocks;


                _.each(vm.stocks, (prod, indprod) => {
                    vm.stock_sent[prod.code] = {};
                    vm.stock_sent[prod.code]['quantite'] = '';
                    vm.stock_sent[prod.code]['quantite_sender'] = prod.quantite;
                    vm.stock_sent[prod.code]['produit_code'] = prod.code;
                    vm.stock_sent[prod.code]['colisage'] = prod.colisage;
                    vm.stock_sent[prod.code]['prix'] = prod.prix;
                });

                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    }


    vm.getCurrentSender = () => {
        vm.current_sender = vm.selectdepots.filter(item => item.user_code === vm.user_code_sender)[0]
        vm.depot_code_sender = vm.selectdepots.filter(item => item.user_code === vm.user_code_sender)[0]
        vm.depot_code_sender = vm.depot_code_sender.code;
    }

    vm.getCurrentReceiver = () => {
        vm.current_receiver = vm.selectdepots.filter(item => item.user_code === vm.user_code_receiver)[0]
        vm.depot_code_receiver = vm.selectdepots.filter(item => item.user_code === vm.user_code_receiver)[0]
        vm.depot_code_receiver = vm.depot_code_receiver.code;
    }


    vm.calculateStockReceived = () => {
        console.log('stock_sent', vm.stock_sent);
        _.each(vm.stock_sent, (element, index) => {
            console.log(index, element)
        });
        vm.total_colisage_sent = _.reduce(vm.stock_sent, function (memo, num) { return memo + Number(num.stock_colisage_sent); }, 0);
        vm.total_quantite_sent = _.reduce(vm.stock_sent, function (memo, num) { return num.quantite ? memo + Number(num.quantite) : memo; }, 0);
        vm.total_valeur_sent = _.reduce(vm.stock_sent, function (memo, num) { return num.quantite ? memo + (parseFloat(num.prix) * Number(num.quantite)) : memo; }, 0);
        vm.total_colisage_sent = !isNaN(vm.total_colisage_sent) ? vm.total_colisage_sent : 0;
    }

    vm.storeTransfert = () => {
        vm.disable_submit = true;
        if (!vm.current_sender) {
            swal(
                $translate.instant('Attention'),
                $translate.instant('Veuillez bien selectionner le camion expéditeur'),
                'warning'
            ).then(function () {
                vm.disable_submit = false;
            }).catch(function () {
                vm.disable_submit = false;
            });
        } else if (!vm.current_receiver) {
            swal(
                $translate.instant('Attention'),
                $translate.instant('Veuillez bien selectionner le camion recepteur'),
                'warning'
            ).then(function () {
                vm.disable_submit = false;
            }).catch(function () {
                vm.disable_submit = false;
            });
        } else if (vm.current_receiver == vm.current_sender) {
            swal(
                $translate.instant('Attention'),
                $translate.instant('Vous ne pouvez pas mettre le meme camion'),
                'warning'
            ).then(function () {
                vm.disable_submit = false;
            }).catch(function () {
                vm.disable_submit = false;
            });
        } else {
            var tab_prods = [];
            _.each(vm.stock_sent, element => {
                if (element.quantite && element.quantite > 0 && element.quantite <= element.quantite_sender) {
                    tab_prods.push(element);
                }
            })

            if (tab_prods.length > 0) {
                const data = {
                    sender_user: vm.user_code_sender,
                    receiver_user: vm.user_code_receiver,
                    sender_depot: vm.depot_code_sender,
                    receiver_depot: vm.depot_code_receiver,
                    prods: tab_prods
                };
                console.log('data', data);
                WS.post('stocks/storeTransfert', data).then(
                    function (response) {
                        if (response.data.success) {
                            swal(
                                $translate.instant('Succès'),
                                $translate.instant('chargement modifiée avec Succès'),
                                'success'
                            ).then(function () {
                                $window.location.reload();
                            }).catch(function () {
                                $window.location.reload();
                            });

                        } else {
                            swal(
                                $translate.instant('Oups!'),
                                $translate.instant("erreur de traitement"),
                                'warning'
                            );
                        }

                        $scope.$apply();
                    },
                    function (error) {
                        vm.disable_submit = false;
                        console.log('error : ->', error);
                    }
                );

            } else {
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('Veuillez bien saisir au moins une quantite'),
                    'warning'
                ).then(function () {
                    vm.disable_submit = false;
                }).catch(function () {
                    vm.disable_submit = false;
                });
            }
        }
    }

    vm.getMoyByProduct = function (code, liste) {

        if (!liste) return { nb: 0 };
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['produit_code'] == code) {
                return liste[i];
            }
        }
        return { nb: 0 };
    }


    // vm.getChargementByFilter = () => {
    //     const user_code = vm.chargement['ch']['commercial'];
    //     const type = vm.chargement['ch']['type'];
    //     const date_debut = vm.stat_date_debut;
    //     const date_fin = vm.stat_date_fin;
    //     const stat_caisse = vm.stat_stat_caisse; //caisse_oui or caisse_non
    //     const caisse_code = vm.stat_caisse_code;
    //     console.log()
    // }

    vm.getChargementByCommercial = () => {
        vm.show_loader = true;
        const commercial = vm.chargement['ch']['commercial'];
        const type = vm.chargement['ch']['type'];
        const date_debut = vm.chargement.stat_date_debut;
        const date_fin = vm.chargement.stat_date_fin;
        const stat_caisse = vm.chargement.stat_caisse; //caisse_oui or caisse_non
        const caisse_code = vm.chargement.stat_caisse_code;
        const demande_chargement = false;
        const data = {
            commercial, type, date_debut, date_fin, stat_caisse, caisse_code, demande_chargement
        }
        if (vm.chargement.ch.type == 'retour') {

            vm.chargement.ch.demande_chargement = false;

            if (vm.chargement['ch']['commercial'] == '') {
                swal(
                    $translate.instant('Oups'),
                    $translate.instant('Veuillez bien saisir le commercial!'),
                    'warning'
                );
                vm.show_loader = false;
            } else {
                WS.getData('chargements/getChargementByCommercial', data).then(
                    function (response) {
                        if (response.status) {
                            //if(response.data.data.length>0){
                            _.each(vm.produits, (p, index) => {
                                var i = 0;
                                _.each(response.data, (res, ind) => {
                                    if (p.code == res.code) {
                                        _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], {
                                            quantite: res.sum_retour, //retour
                                            demande_chargement_p: 0,
                                            retour_hier_p: 0,
                                            chargement: res.sum_chargement,
                                            vente: res.sum_vente,
                                            gratuite: res.sum_gratuite,
                                            quantite_casse: res.sum_casse,
                                            quantite_dlc: res.sum_dlc,
                                        });
                                        i++;
                                    }
                                });
                                if (i == 0) {
                                    //var sum_retour = res.sum_chargement - res.sum_vente -  res.sum_gratuite - res.sum_casse - res.sum_dlc;
                                    _.extend(vm.chargement['ch'][p.id], vm.chargement['ch'][p.id], {
                                        quantite: 0, //retour
                                        demande_chargement_p: 0,
                                        retour_hier_p: 0,
                                        chargement: 0,
                                        vente: 0,
                                        gratuite: 0,
                                        quantite_casse: 0,
                                        quantite_dlc: 0,
                                    });
                                }
                            });
                            //}

                            /**
                             * init total casier et valeur
                             */
                            vm.total_Casier_chargement();
                        }
                        vm.show_loader = false;
                        $scope.$apply();
                    },
                    function (error) {
                        console.log('error : ->', error);
                        vm.show_loader = false;
                    }
                );
            }
        } else {
            _.each(vm.produits, (el, ind) => {
                _.extend(vm.chargement['ch'][el.id], vm.chargement['ch'][el.id], { quantite: 0, demande_chargement: 0 });
            });
            /**
             * init total casier et valeur
             */
            vm.total_Casier_chargement();
            vm.show_loader = false;
        }
    }

    vm.exportChargementsErpTotal = () => {


        var url = `chargements/exportChargementsErpTotal?date_debut=${vm.stat_date_debut}&date_fin=${vm.stat_date_fin}&type=chargement`;
        if (vm.stat_user_code) {
            url = url + `&user_code=${vm.stat_user_code}`
        }
        $window.location.href = AppSettings.apiUrl + url;

    }

    vm.exportChargementsErp = () => {


        var url = `chargements/exportChargementsErp?date_debut=${vm.stat_date_debut}&date_fin=${vm.stat_date_fin}&type=chargement`;
        if (vm.stat_user_code) {
            url = url + `&user_code=${vm.stat_user_code}`
        }
        $window.location.href = AppSettings.apiUrl + url;

    }

    vm.exportGratuitesErp = () => {

        var url = `chargements/exportChargementsErp?date_debut=${vm.stat_date_debut}&date_fin=${vm.stat_date_fin}&type=gratuite`;
        if (vm.stat_user_code) {
            url = url + `&user_code=${vm.stat_user_code}`
        }
        $window.location.href = AppSettings.apiUrl + url;

    }

    vm.exportDlcCasseErp = () => {

        var url = `chargements/exportChargementsErp?date_debut=${vm.stat_date_debut}&date_fin=${vm.stat_date_fin}&type=dlc`;
        if (vm.stat_user_code) {
            url = url + `&user_code=${vm.stat_user_code}`
        }
        $window.location.href = AppSettings.apiUrl + url;

    }

    vm.exportVenteFactureCl = () => {
        var url = `chargements/exportVenteFactureCl?date_debut=${vm.stat_date_debut}&date_fin=${vm.stat_date_fin}&type=facture_cl`;
        if (vm.stat_user_code) {
            url = url + `&user_code=${vm.stat_user_code}`
        }
        $window.location.href = AppSettings.apiUrl + url;
    }

    vm.exportVenteReelCL = () => {
        var url = `chargements/exportVenteReelCL?date_debut=${vm.stat_date_debut}&date_fin=${vm.stat_date_fin}&type=vente_reel`;
        if (vm.stat_user_code) {
            url = url + `&user_code=${vm.stat_user_code}`
        }
        $window.location.href = AppSettings.apiUrl + url;
    }



    vm.getListPaiement = () => {
        vm.show_loader = true;
        var data = {
            date_debut: vm.stat_date_debut,
            date_fin: vm.stat_date_fin,
            commercial: vm.stat_commercial,
        };
        WS.getData('chargements/getListPaiement', data)
            .then(function (response) {
                vm.show_loader = false;
                vm.list_paiements = response.data.paiements;
                vm.list_mouvements = response.data.mouvements;
                vm.list_clients = response.data.clients;
                vm.list_bls = response.data.bls;

                vm.list_bls_index = {};
                _.each(vm.list_bls, (element, index) => {
                    vm.list_bls_index[element.code] = element;
                })
                $scope.$apply()

            })
    };

    vm.addPaiement = () => {
        console.log(vm.newpaiement);
        if (vm.newpaiement.id) {
            //edit line
        } else {
            //add new line
            WS.post('chargements/addNewPaiement', vm.newpaiement).then(
                function (response) {
                    if (response.data.success) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('Paiement ajouté avec succés'),
                            'success'
                        ).then(function () {
                            vm.getListPaiement();
                        }).catch(function () {
                            vm.getListPaiement();
                        });;
                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant(response.data.message),
                            'warning'
                        );
                    }

                },
                function () {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('erreur de chargement des données!!'),
                        'warning'
                    );
                }
            );
        }
    }

    vm.DeletePaiement = (value) => {
        swal({
            title: $translate.instant('Paiement'),
            text: $translate.instant("voulez vous vraiment supprimer cette ligne!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {

            WS.post('chargements/DeletePaiement', value).then(
                function (response) {
                    if (response.data.success) {
                        vm.getListPaiement();
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('Paiement supprimé avec succès'),
                            'success'
                        ).then(function () {

                        }).catch(function () {
                            //vm.getListPaiement();
                        });;
                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant(response.data.message),
                            'warning'
                        );
                    }

                },
                function () {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('erreur de chargement des données!!'),
                        'warning'
                    );
                }
            );
        });
    }



    /**
     * rapport stat-retour dans bilan journalier
     */
    vm.stat_retour = () => {
        vm.show_loader = true;
        WS.getData('chargements/stat_retour', vm.search)
            .then(function (response) {
                vm.show_loader = false;
                vm.list_mouvements = response.data.mouvements;
                $scope.$apply()

            })
    };

    vm.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };
    /**
    * rapport JournalCommercial dans bilan journalier
    */
    vm.JournalCommercial = () => {
        vm.show_loader = true;
        WS.getData('chargements/JournalCommercial', vm.search)
            .then(function (response) {
                vm.show_loader = false;
                vm.mouvements = response.data.mouvements;
                vm.stocks = response.data.stocks;
                vm.mvt_familles = response.data.familles;
                //vm.stocks_hors_mvt = response.data.stocks_hors_mvt;
                vm.stocks_historique = response.data.stocks_historique;

                vm.total_sum_chargement = 0;
                vm.total_sum_venteC = 0;
                vm.total_sum_cadeauxC = 0;
                vm.total_sum_retourC = 0;
                vm.total_sum_retour = 0;
                vm.total_stocks = 0;
                _.each(vm.mouvements, (element, index) => {
                    vm.total_sum_chargement += element.sum_chargement;
                    vm.total_sum_venteC += element.sum_venteC;
                    vm.total_sum_cadeauxC += element.sum_cadeauxC;
                    vm.total_sum_retourC += element.sum_retourC;
                    vm.total_sum_retour += element.sum_retour;
                })

                _.each(vm.stocks, (element, index) => {
                    vm.total_stocks += element.quantite;
                })


                vm.trs_mvts = ''
                _.each(vm.mvt_familles, (prods, famille_code) => {
                    var fm_total_sum_chargement = 0;
                    var fm_total_sum_venteC = 0;
                    var fm_total_sum_cadeauxC = 0;
                    var fm_total_sum_retourC = 0;
                    var fm_total_sum_retour = 0;
                    var total_stocks = 0
                    var total_stocks_historique = 0
                    _.each(prods, (prod) => {
                        vm.trs_mvts += `
                            <tr>
                                <td class="text-center">${prod.code}</td>
                                <td class="text-center">${prod.produit}</td>
                                <td class="text-center">${vm.stocks_historique[prod.code] ? vm.stocks_historique[prod.code].quantite : 0}</td>
                                <td class="text-center">${prod.sum_chargement}</td>
                                <td class="text-center">${prod.sum_venteC}</td>
                                <td class="text-center">${prod.sum_cadeauxC}</td>
                                <td class="text-center">${prod.sum_retourC}</td>
                                <td class="text-center">${prod.sum_retour}</td>
                                <td class="text-center" title="Livrée + cadeaux - AVoir">
                                    ${prod.sum_venteC + prod.sum_cadeauxC - prod.sum_retourC}
                                </td>
                                <td class="text-center">${vm.stocks[prod.code] ? vm.stocks[prod.code].quantite : ''}</td>
                            </tr>
                        `;
                        fm_total_sum_chargement += prod.sum_chargement;
                        fm_total_sum_venteC += prod.sum_venteC;
                        fm_total_sum_cadeauxC += prod.sum_cadeauxC;
                        fm_total_sum_retourC += prod.sum_retourC;
                        fm_total_sum_retour += prod.sum_retour;

                        total_stocks += vm.stocks[prod.code] ? vm.stocks[prod.code].quantite : 0;
                        total_stocks_historique += vm.stocks_historique[prod.code] ? vm.stocks_historique[prod.code].quantite : 0;
                    })

                    // var total_stocks = vm.stocks.filter(stock => stock.famille_code == famille_code).reduce(
                    //     (accumulateur, valeurCourante) => {return accumulateur + valeurCourante.quantite;}
                    //   );
                    // var total_stocks = _.filter(vm.stocks, stock => stock.famille_code == famille_code);
                    //     total_stocks = _.reduce(total_stocks, function(memo, num){ return memo + num.quantite; }, 0);

                    //famille ligne
                    vm.trs_mvts += `
                            <tr style="background:#89c4f7" title="Famille">
                                <td class="text-center"><b>${$translate.instant('Famille')}</b></td>
                                <td class="text-center">${prods[0].famille}</td>
                                <td class="text-center">${total_stocks_historique}</td>
                                <td class="text-center">${fm_total_sum_chargement}</td>
                                <td class="text-center">${fm_total_sum_venteC}</td>
                                <td class="text-center">${fm_total_sum_cadeauxC}</td>
                                <td class="text-center">${fm_total_sum_retourC}</td>
                                <td class="text-center">${fm_total_sum_retour}</td>
                                <td class="text-center" title="Livrée + cadeaux - AVoir">
                                    ${fm_total_sum_venteC + fm_total_sum_cadeauxC - fm_total_sum_retourC}
                                </td>
                                <td class="text-center">${total_stocks}</td>
                            </tr>
                        `;

                })


                /**
                 * stock hors mouvements
                 */
                // _.each(vm.stocks_hors_mvt, (prods, famille_code) => {
                //     var fm_total_sum_chargement = 0;
                //     var fm_total_sum_venteC = 0;
                //     var fm_total_sum_cadeauxC = 0;
                //     var fm_total_sum_retourC = 0;
                //     var fm_total_sum_retour = 0;
                //     var total_stock = 0;
                //     var total_stock_historique = 0;
                //     _.each(prods, (prod) => {
                //         vm.trs_mvts += `
                //             <tr style="background:antiquewhite">
                //                 <td class="text-center">${prod.produit_code}</td>
                //                 <td class="text-center">${prod.produit}</td>
                //                 <td class="text-center">${vm.stocks_historique[prod.produit_code] ? vm.stocks_historique[prod.produit_code].quantite : 0}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center" title="Livrée + cadeaux - AVoir">0</td>
                //                 <td class="text-center">${prod.quantite}</td>
                //             </tr>
                //         `;
                //         total_stock += prod.quantite;
                //         total_stock_historique += vm.stocks_historique[prod.produit_code] ? vm.stocks_historique[prod.produit_code].quantite : 0;
                //     })


                // var total_stocks = _.filter(vm.stocks, stock => stock.famille_code == famille_code);
                //     total_stocks = _.reduce(total_stocks, function(memo, num){ return memo + num.quantite; }, 0);

                //famille ligne
                //     vm.trs_mvts += `
                //             <tr style="background:#89c4f7" title="Famille">
                //                 <td class="text-center"> <b>${$translate.instant('Famille')}</b></td>
                //                 <td class="text-center">${prods[0].famille}</td>
                //                 <td class="text-center">${total_stock_historique}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center">${0}</td>
                //                 <td class="text-center" title="Livrée + cadeaux - AVoir">
                //                     ${0}
                //                 </td>
                //                 <td class="text-center">${total_stock}</td>
                //             </tr>
                //         `;

                // })
                vm.trs_mvts = vm.trustHtml(vm.trs_mvts);

                $scope.$apply()

            })
    };

    vm.encaissementCommercial = () => {
        vm.show_loader = true;
        WS.getData('chargements/encaissementCommercial', vm.search)
            .then(function (response) {
                vm.show_loader = false;
                vm.encaissements = response.data;

                vm.sum_total_espece = 0;
                vm.sum_total_cheque = 0;
                vm.sum_total_rs = 0;
                vm.sum_total_virement = 0;
                vm.sum_total_traite = 0;

                _.each(vm.encaissements, (element, index) => {
                    vm.sum_total_espece += element.total_espece;
                    vm.sum_total_cheque += element.total_cheque;
                    vm.sum_total_rs += element.total_rs;
                    vm.sum_total_virement += element.total_virement;
                    vm.sum_total_traite += element.total_traite;
                })

                $scope.$apply()

            })
    };
    vm.infoJournalCommercial = () => {
        vm.show_loader = true;
        WS.getData('chargements/infoJournalCommercial', vm.search)
            .then(function (response) {
                vm.show_loader = false;
                vm.infouser = response.data.user;
                vm.societe = response.data.societe;
                $scope.$apply()

            })
    };

    vm.printJournalCommercial = (table) => {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                    <style>
                                        .noprint{display: none;}.text-center{text-align:center}
                                        th{border:1px solid #ddd!important;}
                                        td{border:1px solid #ddd!important;}
                                        #header-journee-commercial{display: flex;justify-content: space-between;}
                                        #header-journee-commercial>div{width:33%;}
                                        #footer-journee-commercial{display: flex;justify-content: space-between;}
                                        #footer-journee-commercial>div{width:50%;}
                                    </style>
                                `);
        mywindow.document.write('</head><body >');

        mywindow.document.write(document.getElementById('panel-header-journee-commercial').innerHTML);
        mywindow.document.write(`<div class="text-center"><b>${vm.entete_print}</b></div>`);

        mywindow.document.write(document.getElementById(table).innerHTML);

        mywindow.document.write(document.getElementById('panel-footer-journee-commercial').innerHTML);

        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

    vm.getAllListCharges = () => {
        WS.getData('chargements/getAllListCharges', vm.search)
            .then(function (response) {
                vm.charges = response.data;

                vm.calculateResNet();
                $scope.$apply()

            })
    };

    vm.getListCharges = () => {
        WS.getData('chargements/getListCharges', vm.search)
            .then(function (response) {
                vm.temps_charges = response.data;
                vm.new_charges = response.data;
                vm.calculateResNet();
                $scope.$apply()

            })
    };

    vm.callNewChargePanel = () => {
        vm.total_of_new_charge = 0;
        console.log(vm.new_charges)

        _.each(vm.new_charges, (element, index) => {
            vm.total_of_new_charge += element.montant;
        })
        console.log(vm.total_of_new_charge)
    }

    vm.calculateResNet = (etat = null) => {
        vm.total_charges = 0;

        _.each(vm.saved_charges, (element) => {
            vm.total_charges += element.type == 'journalier' ? parseFloat(element.montant_journalier) * vm.nbr_jours : parseFloat(element.montant_journalier)
        })


        vm.resultat_net_mensuel = vm.sum_marges_commercial ? vm.sum_marges_commercial.marge_nette - vm.total_charges : - vm.total_charges;
        //vm.resultat_net_journalier = vm.sum_marges_commercial.marge_nette - vm.total_charges;
        //console.log(vm.resultat_net_mensuel, vm.resultat_net_journalier)
    }
    vm.sauvgarde_charges = [];
    vm.getSauvegardeCharges = () => {
        WS.getData('chargements/getSauvegardeCharges', vm.search)
            .then(function (response) {
                if (response.data.length > 0) {
                    //vm.temps_charges = response.data;
                    vm.saved_charges = response.data;
                } else {
                    vm.temps_charges = vm.charges;
                }

                vm.calculateResNet();
                $scope.$apply()

            })
    };

    vm.deleteChargeSauvegarde = (id) => {
        WS.post('chargements/deleteChargeSauvegarde', { id }).then(
            function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('charge supprimé avec succés'),
                        'success'
                    ).then(function () {

                    }).catch(function () {

                    });
                    vm.getSauvegardeCharges();
                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }

            },
            function () {
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('erreur de chargement des données!!'),
                    'warning'
                );
            }
        );
    }

    vm.sauvegarderCharges = () => {
        var user_code = vm.search.user_code;
        var date_debut = vm.search.date_debut;
        var date_fin = vm.search.date_fin;
        console.log('vm.temps_charges', vm.new_charges)
        var data = {
            user_code, date_debut, date_fin,
            charges: vm.new_charges
        }

        WS.post('chargements/sauvegarderCharges', data).then(
            function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('charge ajouté avec succés'),
                        'success'
                    ).then(function () {

                    }).catch(function () {

                    });
                    vm.getSauvegardeCharges();
                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }

            },
            function () {
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('erreur de chargement des données!!'),
                    'warning'
                );
            }
        );
    }

    vm.add_custom_charge = {};
    vm.appendTrCharge = () => {
        var id = vm.new_charges.length + 50000;
        console.log('test', vm.new_charges.length, id);

        vm.add_custom_charge.id = id;
        vm.new_charges.push(vm.add_custom_charge);
        console.log(vm.add_custom_charge, id, vm.new_charges)

        vm.callNewChargePanel();
        vm.calculateResNet();
        vm.add_custom_charge = {}
    }

    vm.removeChargeTemps = (id) => {
        vm.new_charges = vm.new_charges.filter(charge => charge.id !== id);
        vm.callNewChargePanel();
        vm.calculateResNet();
    }



    vm.addNewCharge = () => {
        WS.post('chargements/addNewCharge', vm.charge).then(
            function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('charge ajouté avec succés'),
                        'success'
                    ).then(function () {
                        vm.getAllListCharges();
                        vm.getListCharges();
                        vm.calculateResNet();
                        vm.charge = {};
                    }).catch(function () {
                        vm.getAllListCharges();

                        vm.getListCharges();
                        vm.calculateResNet();
                    });;

                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }

            },
            function () {
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('erreur de chargement des données!!'),
                    'warning'
                );
            }
        );
    };

    vm.deleteCharge = (id) => {
        WS.post('chargements/deleteCharge', { id }).then(
            function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('charge supprimé avec succés'),
                        'success'
                    ).then(function () {
                        vm.getListCharges();
                        vm.calculateResNet();
                    }).catch(function () {
                        vm.getListCharges();
                        vm.calculateResNet();
                    });;
                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant(response.data.message),
                        'warning'
                    );
                }

            },
            function () {
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('erreur de chargement des données!!'),
                    'warning'
                );
            }
        );
    }


    vm.margeCommission = () => {

        WS.getData('chargements/margeCommission', vm.search)
            .then(function (response) {
                vm.marges_commercial = response.data.marges_commercial;
                vm.nbr_jours = response.data.nbr_jours.nbr_jours;
                vm.cumul_ecart_caisse = response.data.cummul_ecart_caisse.sum_ecart;
                vm.sum_marges_commercial = {}
                vm.sum_marges_commercial.vente_nette = 0;
                vm.sum_marges_commercial.quantite = 0;
                vm.sum_marges_commercial.carton = 0;
                vm.sum_marges_commercial.marge_nette = 0;
                vm.sum_marges_commercial.sum_dlc_hors_lait_beurre = 0;
                vm.sum_marges_commercial.total_vente_reel = 0;
                _.each(vm.marges_commercial, (element) => {
                    vm.sum_marges_commercial.vente_nette += element.vente_nette;
                    vm.sum_marges_commercial.quantite += element.quantite;
                    vm.sum_marges_commercial.carton += element.carton;
                    vm.sum_marges_commercial.marge_nette += element.marge_nette;

                    /**
                     * calcule chiffre dlc - lait beurre
                     */
                    vm.sum_marges_commercial.sum_dlc_hors_lait_beurre += element.hors_lait_beurre ? element.chiffre_dlc : 0;

                    /**
                     * calcule chiffre d'ffaire vente reel  - beurre
                     */
                    vm.sum_marges_commercial.total_vente_reel += element.hors_beurre ? element.vente_reel : 0;


                })
                console.log('vm.sum_marges_commercial', vm.sum_marges_commercial)
                vm.calculateResNet();
                $scope.$apply()

            })
    }


    vm.checkMontantBl = () => {


        vm.newpaiement.message = '';
        if (vm.newpaiement.type_operation == "Credit" && vm.newpaiement.entetcommercial_code) {

            if (parseFloat(vm.list_bls_index[vm.newpaiement.entetcommercial_code].montant_total_ttc) < parseFloat(vm.newpaiement.montant)) {
                vm.newpaiement.montant = vm.list_bls_index[vm.newpaiement.entetcommercial_code].montant_total_ttc
                vm.newpaiement.message = "Montant saisi est supérieur au montant de BL : " + parseFloat(vm.list_bls_index[vm.newpaiement.entetcommercial_code].montant_total_ttc)

            }
        }

    }



}
export default {
    name: 'chargementController',
    fn: chargementController
};